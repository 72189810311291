@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Chakra+Petch:wght@400;500;600;700&display=swap');

:root {
  --bg-primary: #000000;
  --bg-secondary: #111111;
  --bg-tertiary: #1e1e1e;
  --text-primary: #f8f8f8;
  --text-secondary: #a0a0a0;
  --accent-primary: #FF6B00; /* Updated to match logo orange */
  --accent-secondary: #FF8C3C; /* Lighter orange */
  --accent-tertiary: rgba(255, 107, 0, 0.1);
  --border-color: #333333;
  --success-color: #10b981;
  --error-color: #ef4444;
  --warning-color: #f59e0b;
  --info-color: #3b82f6;
  --disabled-bg: #222222;
  --disabled-text: #666666;
}

/* Base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.boom-app {
  font-family: 'Chakra Petch', 'Inter', sans-serif; /* Updated font family to match KONK ecosystem */
  background-color: var(--bg-primary);
  color: var(--text-primary);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Header */
.boom-header {
  background-color: var(--bg-primary);
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 100;
  border-bottom: 1px solid var(--border-color);
}

.boom-header-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between; /* Keep this */
  align-items: center;
}

/* Create minimal space for the logo */
.boom-logo {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 700;
  text-decoration: none;
  letter-spacing: -0.5px;
  flex: 0 0 auto; /* Don't allow shrinking */
  margin-right: 20px; /* Fixed spacing after logo */
}

.boom-logo-text {
  color: var(--text-primary);
  background-color: var(--accent-primary);
  padding: 4px 8px;
  border-radius: 4px;
  transform: rotate(-2deg);
  position: relative;
}

.boom-logo-text::before {
  content: '';
  position: absolute;
  top: -10px;
  left: -15px;
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 12h14'/%3E%3Cpath d='M12 5v14'/%3E%3C/svg%3E");
  background-size: contain;
  transform: rotate(-45deg);
}

.boom-logo-launcher {
  background-color: white;
  color: black;
  font-size: 0.8rem;
  padding: 2px 6px;
  border-radius: 2px;
  transform: rotate(3deg) translateY(5px);
}

/* Keep right side elements together */
.boom-header-right {
  display: flex;
  align-items: center;
  gap: 1rem; /* Original gap */
  flex: 1; /* Take remaining space */
  justify-content: flex-end; /* Push to the right side */
}

/* Add animations to restart button */
.boom-restart-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: rgba(255, 107, 0, 0.1);
  border: 1px solid var(--accent-primary);
  border-radius: 4px;
  padding: 8px 12px;
  color: var(--accent-primary);
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.boom-restart-btn:hover {
  background-color: rgba(255, 107, 0, 0.2);
  transform: translateY(-2px);
}

.boom-restart-icon {
  opacity: 0.7;
  transition: transform 0.3s ease;
}

.boom-restart-btn:hover .boom-restart-icon {
  transform: rotate(180deg);
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .boom-logo {
    margin-right: 10px; /* Less spacing on smaller screens */
  }
}

@media (max-width: 480px) {
  .boom-header-right {
    gap: 0.75rem;
  }
  
  .boom-restart-btn span {
    display: none;
  }
  
  .boom-restart-btn {
    padding: 8px;
  }
  
  .boom-logo {
    margin-right: 5px; /* Minimal spacing on mobile */
  }
}
/* Main content layout */
.boom-main {
  flex: 1;
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.boom-sidebar {
  width: 200px;
  padding: 2rem 1rem;
  border-right: 1px solid var(--border-color);
}

.boom-content {
  flex: 1;
  padding: 2rem;
  overflow-y: auto;
  height: calc(100vh - 64px); /* Adjust based on header height */
  display: flex;
  flex-direction: column;
}

/* Steps */
.boom-steps {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.boom-step {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--text-secondary);
  transition: all 0.3s ease;
}

.boom-step-number {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.boom-step-label {
  font-size: 0.95rem;
  font-weight: 500;
}

.boom-step.active {
  color: var(--text-primary);
}

.boom-step.active .boom-step-number {
  background-color: var(--accent-primary);
  border-color: var(--accent-primary);
  color: var(--bg-primary);
}

.boom-step.completed .boom-step-number {
  background-color: var(--accent-primary);
  border-color: var(--accent-primary);
  color: var(--bg-primary);
}

/* Chat interface */
.boom-chat {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 1rem;
}

.boom-message {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  animation: message-appear 0.3s ease-out;
}

.boom-message-bot {
  margin-right: 60px;
}

.boom-message-user {
  margin-left: 60px;
  flex-direction: row-reverse;
}

.boom-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--accent-primary);
  color: var(--bg-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 0.9rem;
}

.boom-avatar-user {
  background-color: var(--info-color);
}

.boom-message-bubble {
  background-color: var(--bg-secondary);
  border-radius: 12px;
  padding: 1rem;
  max-width: 80%;
  border: 1px solid var(--border-color);
  line-height: 1.5;
  position: relative;
}

/* Special styling for message bubbles that contain options */
.boom-message-bubble:has(.boom-options) {
  background-color: rgba(0, 0, 0, 0.6);
  border: 1px solid #333;
}

.boom-message-user .boom-message-bubble {
  background-color: var(--bg-tertiary);
}

.boom-message-status {
  background-color: var(--bg-tertiary) !important;
  color: var(--text-secondary);
}

.boom-message-success {
  background-color: rgba(16, 185, 129, 0.1) !important;
  border-left: 3px solid var(--success-color);
}

/* Typing indicator */
.boom-typing-indicator {
  display: flex;
  align-items: center;
  gap: 4px;
  min-height: 24px;
  min-width: 52px;
}

.boom-typing-dot {
  width: 8px;
  height: 8px;
  background-color: var(--text-secondary);
  border-radius: 50%;
  animation: bounce 0.6s infinite alternate;
}

.boom-typing-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.boom-typing-dot:nth-child(3) {
  animation-delay: 0.4s;
}

/* Options */
.boom-options {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.boom-option-btn {
  background-color: #1e1e1e;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 12px 16px;
  color: var(--text-primary);
  font-size: 0.95rem;
  font-weight: 500;
  text-align: left;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-bottom: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.boom-option-btn:hover {
  background-color: #2a2a2a;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Disabled option button for "Coming Soon" chains */
.boom-option-disabled {
  background-color: var(--disabled-bg);
  color: var(--disabled-text);
  cursor: not-allowed;
  border-color: var(--disabled-bg);
}

.boom-option-disabled:hover {
  background-color: var(--disabled-bg);
  transform: none;
}

.boom-link-btn {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background-color: var(--bg-tertiary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 10px 14px;
  color: var(--text-primary);
  font-size: 0.95rem;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.boom-link-btn:hover {
  background-color: var(--info-color);
  border-color: var(--info-color);
}

.boom-link-icon {
  opacity: 0.7;
}

/* Deploy Options - CHANGED TO VERTICAL STACK */
.boom-deploy-options {
  margin-top: 1rem;
  display: flex;
  flex-direction: column; /* Changed from row to column */
  gap: 0.75rem;
  align-items: stretch; /* Stretch buttons to fill width */
}

.boom-deploy-options .boom-option-btn {
  width: 100%; /* Full width */
  text-align: center;
}

/* Updated deploy button to be used inside the chat */
.boom-deploy-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: var(--accent-primary);
  border: none;
  border-radius: 4px;
  padding: 14px 24px;
  color: var(--bg-primary);
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%; /* Full width */
  text-align: center;
}

.boom-deploy-btn:hover {
  background-color: var(--accent-secondary);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(255, 107, 0, 0.25);
}

.boom-deploy-btn:disabled {
  background-color: var(--disabled-bg);
  color: var(--disabled-text);
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.boom-deploy-btn.loading {
  background-color: var(--bg-tertiary);
  color: var(--text-secondary);
  cursor: wait;
  transform: none;
  box-shadow: none;
}

.boom-deploy-btn.loading .boom-spinner {
  display: inline-block;
  margin-right: 8px;
}

/* Form container */
.boom-form-container {
  background-color: var(--bg-secondary);
  border-radius: 12px;
  padding: 1.5rem;
  border: 1px solid var(--border-color);
  margin-right: 60px;
  animation: form-appear 0.3s ease-out;
}

.boom-form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.boom-form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.boom-form-group label {
  font-size: 0.95rem;
  font-weight: 500;
  color: var(--text-primary);
}

.boom-form-help {
  margin-top: 0.25rem;
  font-size: 0.85rem;
  color: var(--text-secondary);
}

.boom-form-group input {
  background-color: var(--bg-tertiary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 12px 14px;
  color: var(--text-primary);
  font-size: 0.95rem;
  transition: all 0.2s ease;
}

.boom-form-group input:focus {
  outline: none;
  border-color: var(--accent-primary);
}

.boom-form-group input::placeholder {
  color: var(--text-secondary);
}

.boom-submit-btn {
  background-color: var(--accent-primary);
  color: var(--bg-primary);
  border: none;
  border-radius: 4px;
  padding: 12px;
  font-size: 0.95rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 0.5rem;
}

.boom-submit-btn:hover {
  background-color: var(--accent-secondary);
}

/* Features toggle styling */
.boom-features-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.boom-feature-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  background-color: var(--bg-tertiary);
  border-radius: 4px;
  border: 1px solid var(--border-color);
}

.boom-feature-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.boom-feature-name {
  font-size: 0.95rem;
  font-weight: 500;
}

.boom-feature-description {
  font-size: 0.85rem;
  color: var(--text-secondary);
}

.boom-toggle {
  position: relative;
  width: 40px;
  height: 20px;
  background-color: var(--border-color);
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.boom-toggle.active {
  background-color: var(--accent-primary);
}

.boom-toggle-slider {
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: var(--text-primary);
  border-radius: 50%;
  top: 2px;
  left: 2px;
  transition: transform 0.2s ease;
}

.boom-toggle.active .boom-toggle-slider {
  transform: translateX(20px);
  background-color: var(--bg-primary);
}

/* Add spinner for loading states */
.boom-spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spinner 0.8s linear infinite;
}

/* Animations */
@keyframes message-appear {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes form-appear {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-4px);
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .boom-main {
    flex-direction: column;
  }
  
  .boom-sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid var(--border-color);
    padding: 1rem;
  }
  
  .boom-steps {
    flex-direction: row;
    justify-content: space-between;
  }
  
  .boom-content {
    padding: 1.5rem 1rem;
  }
  
  .boom-step-label {
    display: none;
  }
}

@media (max-width: 768px) {
  .boom-message-bot {
    margin-right: 0;
  }
  
  .boom-message-user {
    margin-left: 0;
  }
  
  .boom-form-container {
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .boom-header-right {
    gap: 0.5rem;
  }
  
  .boom-restart-btn span {
    display: none;
  }
  
  .boom-restart-btn {
    padding: 8px;
  }
}

/* Add these styles to your BoomTokenCreator.css file */

.boom-contract-success {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.boom-contract-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--success-color);
  margin-bottom: 0.5rem;
}

.boom-contract-details {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.75rem;
  border-radius: 4px;
}

.boom-contract-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.boom-contract-label {
  font-weight: 500;
  color: var(--text-secondary);
}

.boom-contract-value {
  font-weight: 600;
}

.boom-contract-address-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.75rem;
  border-radius: 4px;
}

.boom-contract-address-label {
  font-weight: 500;
  color: var(--text-secondary);
}

.boom-contract-address {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--bg-primary);
  padding: 0.5rem;
  border-radius: 4px;
  overflow: hidden;
}

.boom-contract-address code {
  font-family: monospace;
  font-size: 0.9rem;
  word-break: break-all;
  flex: 1;
  color: var(--accent-primary);
}

.boom-copy-btn {
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.boom-copy-btn:hover {
  color: var(--text-primary);
  background-color: rgba(255, 255, 255, 0.1);
}

.boom-contract-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-top: 0.5rem;
}

.boom-view-contract-btn, .boom-holders-btn, .boom-reset-btn {
  padding: 0.75rem 1rem;
  border-radius: 4px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
  flex: 1;
  text-align: center;
  text-decoration: none;
}

.boom-view-contract-btn {
  background-color: var(--bg-tertiary);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
}

.boom-view-contract-btn:hover {
  background-color: var(--border-color);
}

.boom-holders-btn {
  background-color: var(--bg-tertiary);
  color: var(--info-color);
  border: 1px solid var(--info-color);
}

.boom-holders-btn:hover {
  background-color: rgba(59, 130, 246, 0.1);
}

.boom-reset-btn {
  background-color: var(--accent-primary);
  color: var(--bg-primary);
  border: none;
  cursor: pointer;
}

.boom-reset-btn:hover {
  background-color: var(--accent-secondary);
}

/* Verification guide styles */
.boom-message-info {
  background-color: rgba(51, 102, 204, 0.1) !important;
  border-left: 3px solid var(--info-color);
}

.boom-verification-guide {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.boom-verification-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--info-color);
  margin-bottom: 0.5rem;
}

.boom-verification-intro {
  margin-bottom: 0.5rem;
  line-height: 1.5;
}

.boom-verification-steps {
  padding-left: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.boom-verification-steps li {
  margin-bottom: 0.5rem;
  line-height: 1.5;
}

.boom-verification-note {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.75rem;
  border-radius: 4px;
  margin-top: 0.5rem;
}

.boom-verification-note p {
  font-size: 0.9rem;
  line-height: 1.5;
}

.boom-verification-note a {
  color: var(--accent-primary);
  text-decoration: none;
  margin: 0 0.25rem;
}

.boom-verification-note a:hover {
  text-decoration: underline;
}

.boom-verify-btn {
  background-color: var(--info-color);
  color: var(--text-primary);
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
  text-align: center;
  text-decoration: none;
  width: fit-content;
  margin-top: 0.5rem;
}

.boom-verify-btn:hover {
  background-color: rgba(59, 130, 246, 0.8);
}

/* Auto-verification button */
.boom-verify-contract-btn {
  background-color: var(--success-color);
  color: var(--bg-primary);
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
  flex: 1;
  text-align: center;
  cursor: pointer;
}

.boom-verify-contract-btn:hover {
  background-color: rgba(16, 185, 129, 0.8);
}

.boom-verify-contract-btn:disabled {
  background-color: var(--disabled-bg);
  color: var(--disabled-text);
  cursor: not-allowed;
}

.boom-verify-contract-btn.loading {
  background-color: var(--bg-tertiary);
  color: var(--text-secondary);
  cursor: wait;
}

.boom-verify-contract-btn .boom-spinner {
  display: inline-block;
  margin-right: 8px;
}

/* Verification status section */
.boom-verification-status {
  margin-top: 1rem;
  padding: 0.75rem;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
}

.boom-verification-status.success {
  border-left: 3px solid var(--success-color);
}

.boom-verification-status.pending {
  border-left: 3px solid var(--warning-color);
}

.boom-verification-status.error {
  border-left: 3px solid var(--error-color);
}

.boom-verification-status-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.boom-verification-status-message {
  font-size: 0.9rem;
  line-height: 1.5;
}

/* Add these to your BoomTokenCreator.css file */

.boom-manual-verification {
  background-color: rgba(17, 17, 17, 0.8);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 1.25rem;
  margin-top: 1rem;
}

.boom-verification-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  color: var(--accent-primary);
}

.boom-verification-info {
  margin-bottom: 1rem;
}

.boom-verification-steps {
  margin-bottom: 1.5rem;
}

.boom-verification-steps ol {
  padding-left: 1.5rem;
}

.boom-verification-steps li {
  margin-bottom: 1rem;
  line-height: 1.5;
}

.boom-verification-steps ul {
  list-style-type: none;
  margin: 0.5rem 0;
  padding-left: 1rem;
  border-left: 2px solid var(--border-color);
}

.boom-verification-steps ul li {
  margin-bottom: 0.5rem;
}

.boom-verification-steps a {
  color: var(--info-color);
  text-decoration: none;
}

.boom-verification-steps a:hover {
  text-decoration: underline;
}

.boom-copy-container {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 0.5rem;
  margin: 0.5rem 0;
  gap: 0.5rem;
}

.boom-copy-container code {
  font-family: monospace;
  font-size: 0.85rem;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.boom-copy-inline-btn {
  background-color: var(--bg-tertiary);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.boom-copy-inline-btn:hover {
  background-color: var(--border-color);
}

.boom-constructor-args-container {
  margin: 0.75rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.boom-constructor-args {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 0.75rem;
  border-radius: 4px;
  font-family: monospace;
  font-size: 0.85rem;
  overflow-x: auto;
  white-space: pre-wrap;
  word-break: break-all;
  border-left: 3px solid var(--accent-primary);
}

.boom-copy-args-btn {
  align-self: flex-start;
  background-color: var(--accent-primary);
  color: var(--bg-primary);
  border: none;
  border-radius: 4px;
  padding: 0.5rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.boom-copy-args-btn:hover {
  background-color: var(--accent-secondary);
}

.boom-verification-links {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.boom-verification-link {
  background-color: var(--info-color);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  font-size: 0.95rem;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.2s ease;
  text-align: center;
  display: inline-block;
}

.boom-verification-link:hover {
  background-color: rgba(59, 130, 246, 0.8);
}

.konk-requirement-modal {
  border: 1px solid var(--accent-primary);
  box-shadow: 0 0 20px rgba(255, 107, 0, 0.15);
}

.modal-header {
  background-color: var(--accent-primary);
  padding: 1.25rem;
  border-bottom: 1px solid var(--border-color);
}

.modal-header h3 {
  margin: 0;
  font-size: 1.2rem;
  color: var(--bg-primary);
  font-weight: 600;
}

.minimum-requirement, .current-balance {
  background-color: var(--bg-tertiary);
  padding: 0.75rem 1rem;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.minimum-requirement {
  border-left: 3px solid var(--accent-primary);
}

.current-balance {
  border-left: 3px solid var(--info-color);
}

.requirement-label, .balance-label {
  font-weight: 500;
  color: var(--text-secondary);
}

.requirement-value, .balance-value {
  font-weight: 600;
  font-size: 1.1rem;
}

.requirement-value {
  color: var(--accent-primary);
}

.balance-value {
  color: var(--info-color);
}

.requirement-explanation {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 1rem;
  border-radius: 8px;
  font-size: 0.95rem;
  line-height: 1.5;
}

.requirement-explanation p {
  margin-bottom: 0.75rem;
}

.requirement-explanation p:last-child {
  margin-bottom: 0;
}

.button-confirm {
  background-color: var(--accent-primary);
  color: var(--bg-primary);
  border: none;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.button-confirm:hover {
  background-color: var(--accent-secondary);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(255, 107, 0, 0.25);
}

/* Modern Thin Scrollbar CSS */

/* Apply custom scrollbar to the chat container */
.boom-content, .boom-chat {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: var(--border-color) transparent; /* Firefox */
}

/* Chrome, Safari, and newer Edge scrollbar styling */
.boom-content::-webkit-scrollbar,
.boom-chat::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.boom-content::-webkit-scrollbar-track,
.boom-chat::-webkit-scrollbar-track {
  background: transparent;
  margin: 4px 0;
}

.boom-content::-webkit-scrollbar-thumb,
.boom-chat::-webkit-scrollbar-thumb {
  background-color: rgba(255, 107, 0, 0.3);
  border-radius: 3px;
  transition: background-color 0.3s ease;
}

.boom-content::-webkit-scrollbar-thumb:hover,
.boom-chat::-webkit-scrollbar-thumb:hover {
  background-color: var(--accent-primary);
}

/* Add a subtle fade effect at the bottom of the chat container to indicate more content */
.boom-chat::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: linear-gradient(to top, var(--bg-primary), transparent);
  pointer-events: none;
  opacity: 0.8;
  z-index: 10;
}

/* Adjust chat container to accommodate the fade effect */
.boom-chat {
  position: relative;
  padding-bottom: 40px;
}

/* Ensure smooth scrolling for the whole page */
html {
  scroll-behavior: smooth;
}

/* Make the main chat container take full height */
.boom-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px); /* Adjusted for header height */
  overflow: hidden;
}

.boom-chat {
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* For smoother scrolling on iOS */
}

/* Ensure that when new messages appear, the scrolling is smooth */
.boom-message {
  transition: all 0.2s ease-out;
}

/* Only target mobile-specific issues while preserving desktop experience */

/* ===== RESTART BUTTON ANIMATION - WORKS ON ALL DEVICES ===== */
.boom-restart-icon {
  transition: transform 0.3s ease;
}

.boom-restart-btn:hover .boom-restart-icon {
  transform: rotate(180deg);
}

/* ===== MOBILE-SPECIFIC FIXES ===== */
@media (max-width: 768px) {
  /* Better header for mobile */
  .boom-header {
    padding: 0.5rem 0.75rem;
  }
  
  .boom-logo {
    margin-right: 10px;
  }
  
  .boom-restart-btn span {
    display: none; /* Hide text on mobile */
  }
  
  /* Scrollbar for chat area */
  .boom-chat::-webkit-scrollbar {
    width: 4px;
  }
  
  .boom-chat::-webkit-scrollbar-thumb {
    background-color: rgba(255, 107, 0, 0.3);
    border-radius: 3px;
  }
  
  /* Adjust message bubbles */
  .boom-message-bot {
    margin-right: 20px;
  }
  
  .boom-message-user {
    margin-left: 20px;
  }
  
  .boom-message-bubble {
    padding: 0.875rem;
    font-size: 0.95rem;
    max-width: 100%;
    word-break: break-word;
  }
}

/* ===== SMALL PHONE FIXES ===== */
@media (max-width: 480px) {
  /* Fix for sidebar numbers becoming horizontal */
  .boom-main {
    flex-direction: column;
  }
  
  .boom-sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid var(--border-color);
    padding: 0.75rem 0.5rem;
  }
  
  .boom-steps {
    flex-direction: row;
    justify-content: space-around;
    gap: 10px;
  }
  
  .boom-step-label {
    display: none;
  }
  
  .boom-content {
    height: auto;
    flex: 1;
  }
  
  /* Smaller logo and buttons */
  .boom-logo {
    font-size: 1.2rem;
    margin-right: 5px;
  }
  
  .boom-logo-launcher {
    font-size: 0.7rem;
    padding: 1px 4px;
  }
  
  .boom-restart-btn {
    padding: 5px 8px;
  }
  
  /* Tighter message spacing */
  .boom-chat {
    padding: 1rem 0.75rem;
  }
  
  .boom-message-bot {
    margin-right: 10px;
  }
  
  .boom-message-user {
    margin-left: 10px;
  }
  
  .boom-avatar {
    width: 28px;
    height: 28px;
    font-size: 0.875rem;
  }
  
  /* Options should be full width */
  .boom-option-btn {
    width: 100%;
    padding: 10px;
  }
}

/* Extra small screens */
@media (max-width: 360px) {
  .boom-step-number {
    width: 24px;
    height: 24px;
    font-size: 0.8rem;
  }
  
  .boom-avatar {
    width: 24px;
    height: 24px;
  }
  
  .boom-message-bubble {
    padding: 0.75rem;
    font-size: 0.875rem;
  }
}

/* Fix for the B avatar size consistency */

/* Base avatar size - ensure consistency */
.boom-avatar {
  width: 32px; /* Fixed width */
  height: 32px; /* Fixed height */
  border-radius: 50%;
  background-color: var(--accent-primary);
  color: var(--bg-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 0.9rem;
  flex-shrink: 0; /* Prevent shrinking */
  min-width: 32px; /* Enforce minimum width */
  min-height: 32px; /* Enforce minimum height */
}

.boom-avatar-user {
  background-color: var(--info-color);
}

/* Override any mobile-specific size reductions */
@media (max-width: 768px) {
  .boom-avatar {
    width: 32px !important; /* Force override */
    height: 32px !important; /* Force override */
    font-size: 0.9rem !important; /* Force override */
    min-width: 32px !important; /* Force minimum width */
    min-height: 32px !important; /* Force minimum height */
  }
}

@media (max-width: 480px) {
  .boom-avatar {
    width: 32px !important; /* Force override */
    height: 32px !important; /* Force override */
    font-size: 0.9rem !important; /* Force override */
  }
}

@media (max-width: 360px) {
  .boom-avatar {
    width: 32px !important; /* Force override */
    height: 32px !important; /* Force override */
  }
}

/* Styling for collapsible transaction data */

/* Container for transaction error message */
.boom-message-transaction-error {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Main error message */
.boom-message-main-error {
  margin-bottom: 12px;
}

/* Button to toggle data visibility */
.boom-toggle-data-btn {
  background-color: var(--accent-primary);
  color: var(--bg-primary);
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 0.85rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  margin: 0;
  align-self: flex-start;
}

.boom-toggle-data-btn:hover {
  background-color: var(--accent-secondary);
}

/* Container for data section */
.boom-message-data-section {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  margin-top: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.boom-message-data-section.expanded {
  max-height: 400px;
  overflow-y: auto;
  margin-top: 10px;
  padding: 8px;
  opacity: 1;
}

/* Pre-formatted code styling */
.boom-message-data-section pre {
  font-family: monospace;
  font-size: 0.85rem;
  white-space: pre-wrap;
  word-break: break-all;
  margin: 0;
  color: var(--text-secondary);
}
/* Add this to your BoomTokenCreator.css file */

/* Fix for contract action buttons on mobile */
@media (max-width: 480px) {
  .boom-contract-actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    width: 100%;
  }

  .boom-view-contract-btn, 
  .boom-reset-btn, 
  .boom-holders-btn, 
  .boom-verify-contract-btn {
    width: 100%;
    height: auto;
    min-height: 50px;
    padding: 8px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
  }
}

/* For very small devices */
@media (max-width: 360px) {
  .boom-contract-actions {
    grid-template-columns: 1fr;
  }
}

/* Contract Summary Styling */
.boom-contract-summary {
  background: linear-gradient(to bottom right, rgba(30, 30, 30, 0.9), rgba(20, 20, 20, 0.9));
  border: 2px solid var(--accent-primary);
  border-radius: 12px;
  padding: 1.5rem;
  margin: 1rem 0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25), inset 0 1px 0 rgba(255, 255, 255, 0.1);
  white-space: pre-line;
  position: relative;
  overflow: hidden;
}

.boom-contract-summary::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(to right, var(--accent-primary), transparent);
}

.boom-contract-summary-title {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: var(--accent-primary);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.boom-contract-summary-title::before {
  content: '📄';
  font-size: 1.25rem;
}

.boom-contract-parameter {
  display: flex;
  margin-bottom: 1rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.boom-contract-parameter:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.boom-param-label {
  font-weight: 500;
  color: var(--text-secondary);
  flex: 0 0 35%;
  padding-right: 1rem;
}

.boom-param-value {
  font-weight: 600;
  color: var(--text-primary);
  word-break: break-all;
}

.boom-param-value.address {
  font-family: monospace;
  font-size: 0.9rem;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  border-left: 3px solid var(--accent-primary);
}

.boom-deploy-prompt {
  background-color: rgba(255, 107, 0, 0.1);
  border: 1px solid var(--accent-primary);
  border-radius: 10px;
  padding: 1.25rem;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.boom-deploy-prompt-icon {
  background-color: var(--accent-primary);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bg-primary);
  font-size: 1.2rem;
}

.boom-deploy-prompt-text {
  font-weight: 500;
  font-size: 1.05rem;
  flex: 1;
}

@media (max-width: 768px) {
  .boom-contract-parameter {
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .boom-param-label {
    flex: 0 0 100%;
  }
}