/* RainbowKit v2 CSS Overrides - Complete reset */

/* =====================
   DESKTOP STYLES
   ===================== */

/* Reset all RainbowKit styles for desktop */
@media screen and (min-width: 481px) {
  /* Desktop - Make all RainbowKit elements transparent */
  .header-right [data-rk] * {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
  
  /* Desktop - Set the main button style */
  .header-right [data-rk] button {
    background-color: #ccef22 !important;
    color: #11140C !important;
    border-radius: 8px !important;
    font-family: "Chakra Petch", sans-serif !important;
    font-size: 0.7rem !important;
    padding: 7px 20px !important;
    margin: 0 !important;
  }
  
  /* Desktop - Remove any extra containers or wrappers that might be causing rectangles */
  .header-right [data-rk] > div > div:not(:has(button)) {
    display: none !important;
  }
}

/* =====================
   MOBILE STYLES
   ===================== */

/* Mobile styles */
@media screen and (max-width: 480px) {
  /* Button styles */
  .ios-wallet-connect [data-rk] button {
    background-color: rgba(35, 35, 35, 0.9) !important;
    color: #ccef22 !important;
    border: 1px solid rgba(204, 239, 34, 0.3) !important;
    border-radius: 5px !important;
    font-family: "Chakra Petch", sans-serif !important;
    font-size: 0.7rem !important;
    padding: 6px 14px !important;
  }
  
  /* Connected wallet state */
  .ios-wallet-connect [data-rk] > div {
    background-color: transparent !important;
    border: none !important;
  }
  
  /* Make sure all text is visible */
  .ios-wallet-connect [data-rk] span,
  .ios-wallet-connect [data-rk] div,
  .ios-wallet-connect [data-rk] p {
    color: #ccef22 !important;
    font-family: "Chakra Petch", sans-serif !important;
  }
  
  /* Hide unnecessary elements */
  .ios-wallet-connect [data-rk] img {
    display: none !important;
  }
} 