@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: "Chakra Petch", sans-serif;
  background-color: #11140C;
  background-image: url('./bg.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

.konkland-container {
  color: #FCFDC7;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  min-height: 100vh;
  opacity: 0;
  animation: fadeIn 1.5s ease-in-out forwards;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  position: relative;
  z-index: 1;
}

.konkland-container.menu-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(17, 20, 12, 0.9);
  padding: 1px 20px;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.header.visible {
  transform: translateY(0);
}

.header.hidden {
  transform: translateY(-100%);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.header-title-image {
  height: 80px;
  width: auto;
}

.header-right {
  display: flex;
  align-items: center;
}

/* Styles for the RainbowKit ConnectButton */
.header-right > div > button {
  background-color: #ccef22 !important;
  color: #11140C !important;
  border: none !important;
  padding: 7px 20px !important;
  font-size: 0.7rem !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
  font-family: "Chakra Petch", sans-serif !important;
}

.header-right > div > button:hover {
  background-color: #b8d81f !important;
}

.burger-menu {
  display: none;
  background: none;
  border: none;
  color: #FCFDC7;
  font-size: 28px;
  cursor: pointer;
  margin-left: 20px;
}

.mobile-menu {
  display: none;
  position: fixed;
  top: 0;
  right: -70%;
  bottom: 0;
  width: 70%;
  background-color: rgba(17, 20, 12, 0.95);
  padding: 20px;
  z-index: 1000;
  overflow-y: auto;
  transition: right 0.3s ease-in-out;
}

.mobile-menu.open {
  right: 0;
}

.konkland-main {
  margin-top: 100px;
  padding: 1rem;
  flex-grow: 1;
  position: relative;
  z-index: 2;
}

.thumbnails-container {
  max-width: 1000px;
  margin: 40px auto 0;
  padding: 0 15px;
}

.thumbnail-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.thumbnail-link {
  flex-basis: calc(33.333% - 20px);
  max-width: calc(33.333% - 20px);
  text-decoration: none;
  color: inherit;
  display: block;
  position: relative;
  border: 2px solid #75835D;
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
  aspect-ratio: 16 / 9;
}

.thumbnail-link:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.thumbnail-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(26, 26, 26, 0.8);
  padding: 10px;
  text-align: center;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.thumbnail-link:hover .thumbnail-info {
  opacity: 1;
}

.thumbnail-title {
  font-size: 14px;
  color: #FCFC03;
  font-weight: 600;
  margin: 0 0 5px 0;
}

.thumbnail-description {
  font-size: 12px;
  color: #9BA885;
  margin: 0;
}

.footer {
  margin-top: auto;
  padding: 20px 0;
  font-size: 14px;
  color: #9BA885;
  position: relative;
  z-index: 3;
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #11140C;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-text {
  font-family: 'Chakra Petch', sans-serif;
  font-size: 48px;
  color: #FCFDC7;
  text-align: center;
  animation: fadeInZoom 2s ease-in-out;
  text-shadow: 0 0 10px #00FF00, 0 0 20px #00FF00, 0 0 30px #00FF00;
}

@keyframes fadeInZoom {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.update-popup {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #FCFC03;
  color: #11140C;
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.update-popup button {
  background-color: #11140C;
  color: #FCFC03;
  border: none;
  padding: 8px 15px;
  margin-top: 10px;
  border-radius: 3px;
  cursor: pointer;
  font-family: "Chakra Petch", sans-serif;
  font-weight: bold;
}

/* Media Queries for Responsive Layout */
@media (max-width: 768px) {
  .header-right > div > button {
    font-size: 0.75rem !important;
    padding: 8px 12px !important;
  }

  .chain-button {
    display: none;
  }

  .connected-container {
    flex-direction: column;
    align-items: flex-end;
  }

  .account-button {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media (max-width: 480px) {
  .header-title-image {
    height: 60px; /* Adjust if needed for very small screens */
  }

  .thumbnail-link {
    flex-basis: 100%;
    max-width: 100%;
  }

  .thumbnail-info {
    opacity: 1;
    position: static;
    background-color: #1a1a1a;
  }

  .konkland-main {
    margin-top: 80px; /* Adjusted for smaller header on mobile */
  }
  
}

/* Ensure the ConnectButton is visible on all screen sizes */
.header-right > div {
  display: flex !important;
  justify-content: flex-end;
}

.header-right > div > button {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

/* Mobile menu styles */
.mobile-menu {
  background-color: #11140C;
  padding: 20px;
  position: fixed;
  top: 70px; /* Adjust based on your header height */
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow-y: auto;
}

.mobile-menu.open {
  display: block;
}

.mobile-menu-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 60px;
}

.connected-container {
  display: flex;
  align-items: center;
}

.account-button, .chain-button {
  background-color: #ccef22;
  color: #11140C;
  border: none;
  padding: 8px 12px;
  font-size: 0.875rem;
  border-radius: 8px;
  cursor: pointer;
  font-family: "Chakra Petch", sans-serif;
  margin: 0 5px;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.social-icon {
  font-size: 24px;
  color: #FCFDC7;
  text-decoration: none;
}

.mobile-menu-button {
  background-color: #ccef22;
  color: #11140C;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  font-family: "Chakra Petch", sans-serif;
  width: 100%;
  max-width: 200px;
}

.wallet-info {
  background-color: #1a1a1a;
  padding: 10px;
  border-radius: 8px;
  font-size: 0.9rem;
}

/* iOS Style Mobile App Layout */
.ios-mobile-layout {
  display: none;
}

/* App Folders */
.app-folders {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  padding: 4px;
  margin-top: 35px;
  max-width: 100%;
  box-sizing: border-box;
}

.app-folder {
  position: relative;
  width: 100%;
  aspect-ratio: 1/1;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 22%;
  overflow: visible;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  margin-bottom: 12px;
  padding: 8px;
}

.app-folder:active {
  transform: scale(0.95);
}

.app-folder-icons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 2px;
  width: 70%;
  height: 70%;
  margin-bottom: 5px;
}

.mini-app-icon {
  border-radius: 8px;
  background-color: transparent;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.mini-app-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.mini-app-icon svg {
  width: 100%;
  height: 100%;
  display: block;
}

.app-folder-name {
  font-size: 11px;
  color: #FCFDC7;
  text-align: center;
  position: absolute;
  bottom: -20px;
  width: 100%;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

/* App Folder Modal (when folder is clicked) */
.folder-modal {
  position: fixed;
  top: 100%;
  left: 0;
  width: 100%;
  height: 85vh;
  background-color: rgba(17, 20, 12, 0.85);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 1100;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  transition: top 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  box-shadow: 0 -5px 25px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.folder-modal.open {
  top: 15vh;
}

.folder-modal-handle {
  width: 40px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  margin: 10px auto;
}

.folder-modal-title {
  font-size: 18px;
  font-weight: 600;
  color: #FCFDC7;
  margin: 0 0 15px 0;
  padding: 0 20px;
  text-align: left;
}

.folder-modal-apps {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
  max-width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

.folder-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: #FCFDC7;
  transition: transform 0.2s;
  -webkit-tap-highlight-color: transparent;
}

.folder-app:active {
  transform: scale(0.95);
}

.folder-app-icon {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  overflow: hidden;
}

.folder-app-icon img,
.folder-app-icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.folder-app-name {
  font-size: 12px;
  text-align: center;
  color: #FCFDC7;
  font-family: 'Chakra Petch', sans-serif;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ios-dock {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 15px;
  background: rgba(17, 20, 12, 0.8);
  padding: 10px 20px;
  border-radius: 20px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(204, 239, 34, 0.2);
  z-index: 1500;
}

.dock-app {
  width: 60px;
  height: 60px;
  border-radius: 25%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
}

.dock-app svg {
  width: 100%;
  height: 100%;
}

.dock-app-button {
  appearance: none;
  outline: none;
}

/* iOS style status bar */
.ios-status-bar {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  padding: 5px 15px;
  z-index: 1200;
  color: #FCFDC7;
  font-size: 12px;
  font-weight: 600;
}

.ios-status-bar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ios-status-time {
  flex: 1;
  text-align: center;
}

.ios-status-icons {
  display: flex;
  gap: 5px;
}

/* Connect wallet button for iOS style mobile */
.ios-wallet-connect {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1001;
}

.ios-wallet-connect button {
  background-color: rgba(35, 35, 35, 0.9) !important;
  color: #ccef22 !important;
  border: 1px solid rgba(204, 239, 34, 0.3) !important;
  border-radius: 5px !important;
  font-family: "Chakra Petch", sans-serif !important;
  font-size: 0.7rem !important;
  padding: 6px 14px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
}

/* Override RainbowKit default styles completely */
.header-right [data-rk],
.header-right [data-rk] * {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

/* Desktop wallet button styles */
.header-right button {
  background-color: #ccef22 !important;
  color: #11140C !important;
  border: none !important;
  border-radius: 8px !important;
  font-family: "Chakra Petch", sans-serif !important;
  font-size: 0.7rem !important;
  padding: 7px 20px !important;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Mobile-specific styles */
@media (max-width: 480px) {
  .ios-wallet-connect {
    display: block;
  }
  
  /* Target all elements in RainbowKit on mobile */
  .ios-wallet-connect [data-rk] * {
    color: #ccef22 !important;
  }
  
  /* Connected wallet state container */
  .ios-wallet-connect [data-rk] > div > div {
    background-color: rgba(35, 35, 35, 0.9) !important;
    border-radius: 5px !important;
    color: #ccef22 !important;
    padding: 4px !important;
  }
  
  /* Balance text */
  .ios-wallet-connect [data-rk] span {
    color: #ccef22 !important;
    font-family: "Chakra Petch", sans-serif !important;
    font-size: 0.7rem !important;
  }
  
  /* Icons in wallet button */
  .ios-wallet-connect [data-rk] img {
    display: none !important;
  }
}

/* Feedback Form Styles */
.feedback-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: #11140C;
  border: none;
  border-radius: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  transform: translateZ(0);
  overflow: hidden;
  pointer-events: none;
}

.feedback-modal.open {
  opacity: 1;
  visibility: visible;
  position: fixed;
  overflow: auto;
  pointer-events: auto;
}

/* Hide all other UI elements when feedback modal is open */
body.feedback-open .konkland-container,
body.feedback-open .header,
body.feedback-open .ios-status-bar,
body.feedback-open .ios-wallet-connect,
body.feedback-open .view-switcher-icon,
body.feedback-open .top-controls,
body.feedback-open .konkland-main,
body.feedback-open .ios-dock,
body.feedback-open .footer {
  display: none !important;
}

.feedback-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #ccef22;
  color: #11140C;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 10;
  width: 100%;
  box-sizing: border-box;
}

.feedback-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Chakra Petch', sans-serif;
  color: #11140C;
}

.feedback-close-btn {
  background: none;
  border: none;
  color: #11140C;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  transition: background-color 0.2s;
}

.feedback-close-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.feedback-form {
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  background-color: #11140C;
  color: #FCFDC7;
}

.feedback-form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.feedback-form-group label {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #ccef22;
}

.feedback-textarea, 
.feedback-input {
  background-color: #1e2116;
  border: 1px solid rgba(204, 239, 34, 0.3);
  border-radius: 8px;
  padding: 12px;
  color: #FCFDC7;
  font-family: 'Chakra Petch', sans-serif;
  resize: none;
  transition: border-color 0.3s;
}

.feedback-textarea:focus, 
.feedback-input:focus {
  outline: none;
  border-color: #ccef22;
  background-color: #252a1d;
}

.feedback-submit-btn {
  background-color: #ccef22;
  color: #11140C;
  border: none;
  border-radius: 8px;
  padding: 12px 20px;
  margin-top: 10px;
  font-weight: 600;
  cursor: pointer;
  font-family: 'Chakra Petch', sans-serif;
  transition: background-color 0.3s;
  align-self: flex-end;
}

.feedback-submit-btn:hover {
  background-color: #b8d81f;
}

/* Success message styles */
.feedback-success {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: #FCFDC7;
  background-color: #11140C;
  height: 100%;
  width: 100%;
}

.feedback-success-icon {
  width: 80px;
  height: 80px;
  background-color: #ccef22;
  color: #11140C;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  margin-bottom: 20px;
  font-weight: bold;
}

.feedback-success h3 {
  color: #ccef22;
  font-size: 24px;
  margin: 0 0 10px 0;
  font-family: 'Chakra Petch', sans-serif;
}

.feedback-success p {
  color: #FCFDC7;
  font-size: 16px;
  font-family: 'Chakra Petch', sans-serif;
}

@media (max-width: 480px) {
  /* Apply iOS mobile layout */
  .konkland-main {
    margin-top: 40px;
    padding: 0;
  }
  
  .ios-mobile-layout {
    display: block;
  }
  
  .ios-status-bar {
    display: block;
  }

  .ios-wallet-connect {
    display: block;
  }
  
  .header {
    display: none;
  }
  
  .thumbnails-container {
    display: none;
  }
  
  /* Add blurred wallpaper effect */
  .konkland-container {
    background-image: url('https://i.imgur.com/gW5eVSI.jpeg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }
  
  .konkland-container::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(17, 20, 12, 0.3);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    z-index: -1;
  }
  
  .thumbnail-wrapper {
    display: none;
  }
  
  /* Fix footer overlapping with dock */
  .footer {
    margin-bottom: 90px;
    padding-bottom: 0;
    position: relative;
    z-index: 100;
  }
}

/* Customize RainbowKit wallet connect button for mobile */
@media (max-width: 480px) {
  .ios-wallet-connect [data-rk] {
    transform: scale(0.9);
  }
  
  /* General fixes for connected wallet */
  .ios-wallet-connect [data-rk] span {
    font-size: 14px !important;
  }
  
  /* Fix for the connected wallet container */
  .ios-wallet-connect [data-rk] [data-rk]:first-child {
    max-width: 160px !important;
    background-color: rgba(25, 25, 25, 0.85) !important;
    border-radius: 5px !important;
    overflow: hidden !important;
  }
  
  /* Hide the Ethereum icon to save space */
  .ios-wallet-connect [data-rk] [data-rk] img {
    display: none !important;
  }
  
  /* Ensure text in the button is visible */
  .ios-wallet-connect [data-rk] [data-rk] span {
    color: #ccef22 !important;
    font-size: 11px !important;
  }
  
  /* Fix for the address text */
  .ios-wallet-connect [data-rk] button {
    padding: 4px 8px !important;
    font-size: 11px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    max-width: 75px !important;
    color: #ccef22 !important;
    background-color: rgba(40, 40, 40, 0.95) !important;
  }
  
  /* Fix for balance display */
  .ios-wallet-connect [data-rk] [data-rk] [data-rk] {
    font-size: 11px !important;
    padding: 0 !important;
    color: #ccef22 !important;
  }
}

/* Fix for connected wallet displays - ensure all text is visible */
.ios-wallet-connect [data-rk] div,
.ios-wallet-connect [data-rk] span,
.ios-wallet-connect [data-rk] p,
.ios-wallet-connect [data-rk] [data-rk] div,
.ios-wallet-connect [data-rk] [data-rk] span,
.ios-wallet-connect [data-rk] [data-rk] p {
  color: #ccef22 !important;
}

.ios-wallet-connect [data-rk] button {
  color: #ccef22 !important;
  background-color: rgba(40, 40, 40, 0.95) !important;
}

/* Ensure the wallet address text is visible with higher specificity */
.ios-wallet-connect [data-rk] [data-testid="account-button-address"] {
  color: #ccef22 !important;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.5) !important;
}

/* Ensure the button background is set correctly for visibility with higher specificity */
.ios-wallet-connect [data-rk] button {
  background-color: rgba(40, 40, 40, 0.95) !important;
  color: #ccef22 !important;
}

/* Override any conflicting styles with higher specificity */
.ios-wallet-connect [data-rk] * {
  color: #ccef22 !important;
}

/* Style for the folder app icons to ensure consistent sizing */
.folder-app-icon img {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain !important;
  display: block !important;
  border-radius: 20% !important;
}

/* Fix RainbowKit v2 styles for the connected state */

/* Force all text to be visible in connected wallet state */
.ios-wallet-connect button,
.ios-wallet-connect span,
.ios-wallet-connect div {
  color: #ccef22 !important;
  font-family: "Chakra Petch", sans-serif !important;
}

/* Set background color for the connected state */
.ios-wallet-connect button {
  background-color: rgba(35, 35, 35, 0.9) !important;
  border: 1px solid rgba(204, 239, 34, 0.3) !important;
}

/* Make sure the wallet address text is visible */
.ios-wallet-connect button span {
  color: #ccef22 !important;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.5) !important;
  font-size: 12px !important;
}

/* Improve jiggle animation for app folders in edit mode */
@keyframes jiggle {
  0% { transform: rotate(0deg) scale(1); }
  25% { transform: rotate(-2deg) scale(1.01); }
  50% { transform: rotate(0deg) scale(1); }
  75% { transform: rotate(2deg) scale(1.01); }
  100% { transform: rotate(0deg) scale(1); }
}

.app-folder.jiggling {
  animation: jiggle 0.5s infinite ease-in-out;
  cursor: move !important;
  transition: transform 0s !important; /* Override any existing transitions */
}

.app-folder.jiggling .app-folder-name {
  opacity: 0.7;
}

/* Enhanced styling for dragging state */
.app-folder.dragging {
  animation: none !important; /* Disable jiggle when actively dragging */
  opacity: 0.8;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  z-index: 1000;
  transition: transform 0.1s ease-out !important;
}

/* Add visual feedback for when an app is being dragged over */
.app-folder.drag-over {
  transform: scale(1.1) !important;
  box-shadow: 0 0 15px rgba(204, 239, 34, 0.5);
  border: 2px dashed rgba(204, 239, 34, 0.5);
}

/* Add transition for smooth movement when reordering */
.app-folder {
  transition: transform 0.2s ease-out, opacity 0.2s ease-out, box-shadow 0.2s ease-out;
}

/* Make delete badge more prominent and interactive - Fix position */
.delete-badge {
  position: absolute;
  top: -12px;
  left: -12px;
  width: 22px;
  height: 22px;
  background-color: rgba(230, 20, 20, 0.9);
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  z-index: 100;
  border: 1.5px solid white;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  transition: transform 0.15s ease, background-color 0.15s ease;
}

.delete-badge:hover {
  transform: scale(1.1);
  background-color: rgba(255, 0, 0, 1);
}

.delete-badge:active {
  transform: scale(0.95);
}

/* Improve edit mode controls */
.edit-mode-controls {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  position: relative;
  z-index: 10;
  margin-bottom: 10px;
}

/* Done button in edit mode */
.done-button {
  background-color: rgba(40, 40, 40, 0.9);
  color: #ccef22;
  border: 1px solid rgba(204, 239, 34, 0.3);
  border-radius: 8px;
  padding: 8px 20px;
  font-family: "Chakra Petch", sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
}

.done-button:hover {
  background-color: rgba(50, 50, 50, 0.9);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.done-button:active {
  transform: scale(0.95);
}

/* Make sure folders can be dragged */
.app-folder {
  touch-action: none; /* Prevents default touch actions for better drag handling */
  user-select: none; /* Prevents text selection during drag */
}

/* Add some spacing for the edit mode */
.ios-mobile-layout {
  padding-top: 10px;
}

/* Styling for dragging state */
.app-folder.dragging {
  opacity: 0.6;
  transform: scale(1.05);
  z-index: 100;
}

.app-folder.drag-over {
  border: 2px dashed rgba(204, 239, 34, 0.5);
}

/* Target the internal rectangle that's appearing inside the button */
.ios-wallet-connect [data-rk] [class*="ConnectButton"] {
  border: none !important; /* Remove any borders that might be creating the rectangle */
}

/* Make sure the rectangle doesn't appear on desktop */
@media screen and (min-width: 768px) {
  .ios-wallet-connect [data-rk] [class*="ConnectButton"],
  .ios-wallet-connect [class*="ConnectButton"] {
    border: none !important;
    box-shadow: none !important;
    background: transparent !important;
  }
  
  /* Ensure no unexpected containers appear inside the button */
  .ios-wallet-connect [data-rk] > div > div {
    background: transparent !important;
    border: none !important;
  }
}

/* Fix for the rectangle around wallet connect button on desktop */
@media screen and (min-width: 481px) {
  /* Override all styles that might create rectangles */
  .ios-wallet-connect [data-rk] * {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
  
  /* Only apply styling to the button itself */
  .ios-wallet-connect [data-rk] button {
    background-color: rgba(25, 25, 25, 0.85) !important;
    color: #ccef22 !important;
    border: 1px solid rgba(204, 239, 34, 0.3) !important;
  }
  
  /* Remove unwanted container borders */
  .ios-wallet-connect [data-rk] > div {
    border: none !important;
    background: transparent !important;
    box-shadow: none !important;
  }
  
  /* This targets the specific container causing the rectangle */
  .ios-wallet-connect [data-rk] [data-rk] {
    background-color: transparent !important;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: none !important;
  }
  
  /* Hide any empty divs that might be causing rectangles */
  .ios-wallet-connect [data-rk] div:empty {
    display: none !important;
  }
}

/* Icon View styles */
.all-apps-view {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px 10px;
}

.app-icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.app-folder-label {
  font-size: 9px;
  color: rgba(204, 239, 34, 0.8);
  background-color: rgba(0, 0, 0, 0.6);
  padding: 2px 6px;
  border-radius: 8px;
  margin-top: 2px;
  text-align: center;
}

/* View switcher icon - only show on mobile */
.view-switcher-icon {
  display: flex;
  background-color: rgba(25, 25, 25, 0.7);
  border: 1px solid rgba(204, 239, 34, 0.3);
  border-radius: 8px;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
}

@media (min-width: 481px) {
  /* Hide view switcher on desktop */
  .view-switcher-icon {
    display: none;
  }
}

@media (max-width: 480px) {
  /* Make sure it's visible on mobile */
  .view-switcher-icon {
    display: flex;
  }
}

/* Icon view mode - truncate long names */
.app-icon-view .folder-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 80px; /* Fixed width for icon + text */
}

.app-icon-view .folder-app-name {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
  margin-top: 5px;
}

@media (max-width: 375px) {
  .app-icon-view .folder-app {
    width: 65px; /* Smaller width for smaller screens */
  }
  
  .app-icon-view .folder-app-name {
    font-size: 10px;
  }
}

/* Icon view grid layout */
.all-apps-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  padding: 4px;
  margin-bottom: 90px; /* Space for dock */
  margin-top: 35px; /* Space below the buttons */
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
}

.app-icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.app-icon-wrapper .folder-app {
  margin-bottom: 4px;
  width: 100%;
  max-width: 100%;
}

.app-icon-wrapper .folder-app-name {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
  text-align: center;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Base icon size */
.folder-app-icon {
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 4px;
  position: relative;
  background-color: transparent;
  border-radius: 12px;
  overflow: hidden;
}

.folder-app-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
  display: block;
}

.folder-app-icon svg {
  width: 100%;
  height: 100%;
  display: block;
}

/* Responsive icon sizing */
@media screen and (max-width: 390px) { /* iPhone 12/11 size */
  .all-apps-grid {
    gap: 6px;
    padding: 4px 2px;
  }

  .folder-app-icon {
    width: 45px !important;
    height: 45px !important;
  }
  
  .app-icon-wrapper .folder-app-name {
    font-size: 9px;
  }
}

@media screen and (max-width: 375px) { /* iPhone SE/smaller devices */
  .all-apps-grid {
    gap: 4px;
    padding: 3px 2px;
  }

  .folder-app-icon {
    width: 42px !important;
    height: 42px !important;
  }
  
  .app-icon-wrapper .folder-app-name {
    font-size: 8px;
  }
}

/* Ensure consistent icon sizing in folders */
.app-folders {
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  padding: 4px;
  margin-top: 35px;
  max-width: 100%;
  box-sizing: border-box;
}

@media screen and (max-width: 390px) {
  .app-folders {
    gap: 6px;
    padding: 4px 2px;
  }
}

@media screen and (max-width: 375px) {
  .app-folders {
    gap: 4px;
    padding: 3px 2px;
  }
}

/* Ensure folder modal icons have the same sizing */
.folder-modal-apps {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
  max-width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

@media (max-width: 390px) {
  .folder-modal-apps {
    gap: 16px;
    padding: 16px;
  }
  
  .folder-app-icon {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 375px) {
  .folder-modal-apps {
    gap: 12px;
    padding: 12px;
  }
  
  .folder-app-icon {
    width: 45px;
    height: 45px;
  }
  
  .folder-app-name {
    font-size: 11px;
  }
}

/* App Modal Styles */
.app-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 2000;
}

.app-modal.open {
  opacity: 1;
  visibility: visible;
}

.app-modal-content {
  background-color: #11140C;
  border: 2px solid #ccef22;
  border-radius: 15px;
  padding: 20px;
  position: relative;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow: hidden; /* Changed from auto to prevent scrollbars */
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.close-button {
  background: none;
  border: none;
  color: #ccef22;
  font-size: 24px;
  cursor: pointer;
  padding: 5px 10px;
  transition: color 0.2s ease;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.close-button:hover {
  color: #b8d81f;
}

/* Update control positions */
.top-controls {
  position: fixed;
  top: 80px;
  right: 20px;
  z-index: 1001;
}

.ios-wallet-connect {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1001;
}

/* Chart Alternative */
.chart-alternative {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  text-align: center;
}

.chart-link {
  display: inline-block;
  background-color: #ccef22;
  color: #11140C;
  font-weight: bold;
  padding: 12px 24px;
  border-radius: 12px;
  text-decoration: none;
  margin-top: 15px;
  transition: all 0.3s ease;
}

.chart-link:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(204, 239, 34, 0.3);
}

.chart-link-small {
  display: inline-block;
  background-color: #ccef22;
  color: #11140C;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 8px;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.3s ease;
}

.chart-link-small:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(204, 239, 34, 0.3);
}

.price-disclaimer {
  text-align: center;
  font-size: 12px;
  color: #999;
  margin-top: -10px;
  margin-bottom: 15px;
}

/* Loading and Error States */
.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #ccef22;
  font-size: 18px;
  background-color: rgba(17, 20, 12, 0.8);
  border-radius: 12px;
}

.error-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  height: 100%;
  color: #ff3b30;
  font-size: 16px;
  text-align: center;
  background-color: rgba(17, 20, 12, 0.8);
  border-radius: 12px;
  padding: 20px;
}

.error-message button {
  background-color: #ccef22;
  color: #11140C;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.error-message button:hover {
  background-color: #b8d81f;
}

/* Price List Styles */
.price-list-container {
  padding: 20px;
}

.price-list-container h2 {
  color: #ccef22;
  margin: 0 0 10px 0;
  font-size: 24px;
}

.price-disclaimer {
  color: #999;
  font-size: 14px;
  margin-bottom: 20px;
}

.price-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.price-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: rgba(17, 20, 12, 0.8);
  border-radius: 12px;
  border: 1px solid rgba(204, 239, 34, 0.2);
}

.price-symbol {
  color: #ccef22;
  font-weight: bold;
  font-size: 18px;
}

.price-value {
  color: white;
  font-size: 18px;
}

.price-change {
  font-size: 16px;
}

.price-change.positive {
  color: #4cd964;
}

.price-change.negative {
  color: #ff3b30;
}

/* Widget Styles */
.dextools-widget {
  margin: 20px 10px 100px;
  padding: 15px;
  background-color: rgba(17, 20, 12, 0.8);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(204, 239, 34, 0.2);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.widget-title {
  color: #ccef22;
  font-size: 16px;
  margin: 0 0 10px 0;
  text-align: left;
  font-weight: 600;
}

@media (max-width: 480px) {
  .dextools-widget {
    margin: 15px 8px 90px;
  }
  
  .widget-title {
    font-size: 14px;
  }
  
  .price-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  
  .price-value {
    font-size: 16px;
  }
  
  .price-change {
    font-size: 14px;
  }
}

/* Step Counter Styles */
.step-counter-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: calc(100% - 60px);
  background-color: rgba(17, 20, 12, 0.95);
}

.step-count {
  text-align: center;
  padding: 30px;
  border-radius: 16px;
  background-color: rgba(204, 239, 34, 0.1);
  border: 1px solid rgba(204, 239, 34, 0.2);
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.step-count h3 {
  color: #ccef22;
  font-size: 28px;
  margin-bottom: 20px;
  font-family: "Chakra Petch", sans-serif;
}

.step-info {
  color: rgba(204, 239, 34, 0.9);
  font-size: 16px;
  line-height: 1.5;
  margin: 20px 0;
  padding: 15px;
  background-color: rgba(204, 239, 34, 0.05);
  border-radius: 8px;
}

.step-note {
  color: rgba(204, 239, 34, 0.7);
  font-size: 14px;
  margin-top: 15px;
  font-style: italic;
}

/* Step Counter Styles */
.step-counter-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.step-value {
  font-size: 64px;
  font-weight: bold;
  color: #ccef22;
  font-family: "Chakra Petch", sans-serif;
}

.step-label {
  font-size: 18px;
  color: rgba(204, 239, 34, 0.8);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.start-tracking-btn {
  margin-top: 20px;
  background-color: rgba(204, 239, 34, 0.2);
  border: 1px solid rgba(204, 239, 34, 0.4);
  color: #ccef22;
  padding: 12px 24px;
  border-radius: 8px;
  font-family: "Chakra Petch", sans-serif;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.start-tracking-btn:hover {
  background-color: rgba(204, 239, 34, 0.3);
  transform: scale(1.05);
}

.start-tracking-btn:active {
  transform: scale(0.95);
}

.update-notification {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  animation: slideIn 0.3s ease-out;
}

.update-notification-content {
  background: rgba(17, 20, 12, 0.95);
  border: 2px solid #ccef22;
  border-radius: 12px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  color: #FCFDC7;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.update-button {
  background: #ccef22;
  color: #11140C;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
}

.update-button:hover {
  background: #b8d81f;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Standalone Feedback Page Styles */
.feedback-page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: #11140C;
  display: flex;
  flex-direction: column;
  overflow: auto;
  z-index: 1;
}

.feedback-page .feedback-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #ccef22;
  color: #11140C;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
}

.feedback-page .back-link {
  color: #11140C;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.feedback-page .back-link:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.top-controls-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.view-switcher-icon {
  display: flex;
  background-color: rgba(25, 25, 25, 0.7);
  border: 1px solid rgba(204, 239, 34, 0.3);
  border-radius: 8px;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
}

.ios-wallet-connect {
  display: flex;
}

.ios-wallet-connect > div {
  transform: scale(0.85);
  transform-origin: right center;
}

/* Snake game specific styles */
.app-modal[class*="snake"] .app-modal-content,
.app-modal.open .app-modal-content {
  background-color: #11140C;
  border-color: #ccef22;
  padding: 0;
  overflow: hidden;
}

.app-modal .modal-header {
  background-color: transparent;
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  margin: 0;
}

/* Ensure SnakeGame component fills the modal content area */
.app-modal-content > div {
  width: 100%;
  height: 100%;
  background-color: #11140C;
}

/* Control visibility of header wallet button vs. mobile wallet button */
@media (max-width: 480px) {
  /* Hide header connect button on mobile */
  .header-right > div {
    display: none;
  }
  
  /* Show mobile connect button and view switcher on mobile */
  .ios-wallet-connect, 
  .view-switcher-container {
    display: block !important;
  }
}

@media (min-width: 481px) {
  /* Hide mobile connect button and view switcher on desktop */
  .ios-wallet-connect, 
  .view-switcher-container {
    display: none !important;
  }
  
  /* Show header connect button on desktop */
  .header-right > div {
    display: flex;
  }
}

/* Snake Game Page Styles */
.snake-game-page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: #11140C;
  display: flex;
  flex-direction: column;
  overflow: auto;
  z-index: 1;
}

.snake-game-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #ccef22;
  color: #11140C;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
}

.snake-game-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Chakra Petch', sans-serif;
  color: #11140C;
}

.snake-game-page .back-link {
  color: #11140C;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.snake-game-page .back-link:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.snake-game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex: 1;
}

.snake-game-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

.snake-game-board {
  box-shadow: 0 0 15px rgba(204, 239, 34, 0.2);
  margin: 0 auto;
  max-width: 360px !important; /* Limit maximum size */
  max-height: 360px !important;
  box-sizing: content-box;
}

.snake-game-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.snake-game-instructions {
  margin-top: 20px;
}

@media (max-width: 600px) {
  .snake-game-board {
    width: 90vw !important;
    height: 90vw !important;
    max-width: 90vw !important;
    max-height: 90vw !important;
  }
}

@media (max-width: 480px) {
  .snake-game-board {
    width: 85vw !important;
    height: 85vw !important;
    max-width: 85vw !important;
    max-height: 85vw !important;
  }
}

/* Vaul Drawer Styles */
[data-vaul-drawer-overlay] {
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

[data-vaul-drawer-content] {
  transform: translateY(100%);
  animation: contentShow 500ms cubic-bezier(0.16, 1, 0.3, 1);
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

/* Ensure the drawer content scrolls smoothly */
[data-vaul-drawer-content] {
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}

/* Style the drawer handle */
[data-vaul-drawer-content] [aria-hidden="true"] {
  background-color: rgba(204, 239, 34, 0.3);
  margin: 12px auto;
  width: 48px;
  height: 6px;
  border-radius: 99px;
}