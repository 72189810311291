:root {
  --primary: #00ff9d;
  --primary-glow: rgba(0, 255, 157, 0.15);
  --secondary: #0066ff;
  --secondary-glow: rgba(0, 102, 255, 0.15);
  --background: #0a0a0a;
  --card-bg: #111111;
  --text: #ffffff;
  --text-secondary: #a0a0a0;
  --danger: #ff4444;
  --danger-glow: rgba(255, 68, 68, 0.15);
  --success: #00ff9d;
  --success-glow: rgba(0, 255, 157, 0.15);
}

body {
  background: var(--background);
  color: var(--text);
}

.konk-hyper-mining-v2 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Header Styles */
header {
  background: var(--card-bg);
  padding: 20px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

h1 {
  color: var(--text);
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin: 0;
}

/* Card Styles */
.card {
  background: rgba(17, 17, 17, 0.7);
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 24px;
}

.card h2 {
  color: #fff;
  font-size: 24px;
  margin-bottom: 20px;
}

.session-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.stat-item {
  background: rgba(25, 25, 25, 0.9);
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.stat-label {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.stat-value {
  color: rgb(0, 255, 157);
  font-size: 24px;
  font-weight: bold;
}

.stat-value.inactive {
  color: rgba(255, 255, 255, 0.4);
}

.stat-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 20px rgba(0, 255, 157, 0.1);
  border-color: var(--primary);
}

.stat-item.highlight {
  background: rgba(0, 255, 157, 0.05);
  border-color: var(--primary);
}

.stat-item.winner-glow {
  box-shadow: 0 0 20px rgba(0, 255, 157, 0.15);
  border: 1px solid rgb(0, 255, 157);
}

/* @media (max-width: 768px) {
  .session-stats {
      grid-template-columns: 1fr;
  }
  
  .stat-item {
      min-height: auto;
  }
} */

/* Mining Style Buttons */
.mining-styles {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin: 24px 0;
}

.style-button {
  padding: 16px;
  border-radius: 12px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.style-button.smart-ramp {
  background: var(--primary-glow);
  color: var(--primary);
  border: 1px solid var(--primary);
}

.style-button.smart-ramp.active {
  background: var(--primary);
  color: black;
  box-shadow: 0 0 20px var(--primary-glow);
}

.style-button.aggressive {
  background: var(--danger-glow);
  color: var(--danger);
  border: 1px solid var(--danger);
}

.style-button.aggressive.active {
  background: var(--danger);
  color: white;
  box-shadow: 0 0 20px var(--danger-glow);
}

.mining-style-option {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.style-description {
  font-size: 12px;
  color: var(--text-secondary);
  text-align: center;
  opacity: 0.8;
  padding: 0 8px;
}

/* Multiplier Slider */
.multiplier-control {
  margin: 24px 0;
}

.multiplier-slider {
  width: 100%;
  height: 4px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  appearance: none;
  margin: 20px 0;
}

.multiplier-slider::-webkit-slider-thumb {
  appearance: none;
  width: 24px;
  height: 24px;
  background: var(--primary);
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 10px var(--primary-glow);
  transition: transform 0.2s ease;
}

.multiplier-slider::-webkit-slider-thumb:hover {
  transform: scale(1.1);
}

/* Deposit Button */
.deposit-button {
  width: 100%;
  padding: 16px;
  border-radius: 12px;
  border: none;
  background: var(--primary);
  color: black;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 0 20px var(--primary-glow);
}

.deposit-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 0 30px var(--primary-glow);
}

.deposit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Status Badges */
.status-badge {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 9999px;
  font-size: 14px;
  font-weight: 500;
}

.status-badge.open {
  background: var(--success-glow);
  color: var(--success);
  border: 1px solid var(--success);
}

.status-badge.mining {
  background: var(--secondary-glow);
  color: var(--secondary);
  border: 1px solid var(--secondary);
}

.status-badge.locked {
  background: var(--danger-glow);
  color: var(--danger);
  border: 1px solid var(--danger);
}

/* Loading States */
.loading-text {
  font-size: large;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top-color: white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Notifications */
.error-message {
  margin-top: 16px;
  padding: 12px;
  border-radius: 12px;
  background: var(--danger-glow);
  color: var(--danger);
  border: 1px solid var(--danger);
  display: flex;
  align-items: center;
  gap: 8px;
}

.transaction-notification {
  position: fixed;
  top: 24px;
  right: 24px;
  background: var(--card-bg);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  animation: slideIn 0.3s ease-out;
  z-index: 1000;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Add these additional styles to the previous CSS */

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.2s ease-out;
}

.modal-content {
  background: var(--card-bg);
  border-radius: 16px;
  border: 2px solid #00ff9d; /* Add a 2px solid green border */
  padding: 24px;
  max-width: 500px;
  width: 70%;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.4), 0 0 20px #00ff9d; /* Add a green box shadow */
  animation: scaleIn 0.2s ease-out;
  text-align: center;
}

.modal-header {
  margin-bottom: 20px;
}

.modal-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--text);
}

.modal-body {
  margin-bottom: 24px;
}

.modal-message {
  background: rgba(255, 255, 255, 0.05);
  padding: 16px;
  border-radius: 12px;
  font-family: monospace;
  color: var(--text);
  white-space: pre-wrap;
  text-align: center; /* Change this line to center the text */
}

.modal-footer {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.modal-button {
  padding: 12px 24px;
  border-radius: 12px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.button-cancel {
  background: rgba(255, 255, 255, 0.1);
  color: var(--text);
}

.button-cancel:hover {
  background: rgba(255, 255, 255, 0.15);
}

.button-confirm {
  background: var(--primary);
  color: black;
}

.button-confirm:hover {
  box-shadow: 0 0 20px var(--primary-glow);
}

/* User Position Info */
.user-position {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 20px;
  margin: 24px 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.user-position h3 {
  color: var(--text);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
}

.position-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.detail-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-item .label {
  color: var(--text-secondary);
}

.detail-item .value {
  color: var(--primary);
  font-weight: 500;
}

.mining-indicator {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--secondary);
  font-size: 14px;
}

.mining-indicator:before {
  content: '';
  width: 8px;
  height: 8px;
  background: var(--secondary);
  border-radius: 50%;
  animation: pulse 1.5s infinite;
}

/* Value Display */
.multiplier-value {
  text-align: center;
  color: var(--text);
  font-size: 14px;
  margin-top: 8px;
}

.fee-info {
  color: var(--text-secondary);
  font-size: 12px;
  margin-left: 8px;
}

/* Transaction Hash Link */
.transaction-hash {
  color: var(--primary);
  text-decoration: none;
  font-size: 12px;
  opacity: 0.8;
  transition: opacity 0.2s ease;
}

.transaction-hash:hover {
  opacity: 1;
}

.konkhypers-footer {
  margin-top: 40px;
  text-align: center;
  padding: 20px;
}

.konkhypers-footer-content {
  opacity: 0.7;
  transition: opacity 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.konkhypers-footer-content:hover {
  opacity: 1;
}

.konkhypers-blast-logo {
  max-width: 150px;
  height: auto;
}

.konkhypers-blast-svg {
  max-width: 150px;
  height: auto;
  filter: invert(79%) sepia(100%) saturate(463%) hue-rotate(195deg) brightness(200%) contrast(98%);
}

@keyframes glow {
  from {
      box-shadow: 0 0 10px rgba(0, 255, 157, 0.15);
  }
  to {
      box-shadow: 0 0 20px rgba(0, 255, 157, 0.3);
  }
}

/* Responsive Design */
/* @media (max-width: 768px) {
  .konk-hyper-mining-v2 {
      padding: 16px;
  }

  .session-stats {
      grid-template-columns: 1fr;
  }

  .mining-styles {
      grid-template-columns: 1fr;
  }

  .modal-content {
      width: 95%;
      margin: 20px;
  }

  .stat-item {
      padding: 12px;
  }

  .stat-value {
      font-size: 18px;
  }
} */

/* Additional Utility Classes */
.text-gradient {
  background: linear-gradient(to right, var(--primary), var(--secondary));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.glow-effect {
  position: relative;
}

.glow-effect::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  box-shadow: 0 0 20px var(--primary-glow);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.glow-effect:hover::after {
  opacity: 1;
}

/* Blocks Section */
.blocks-section {
  margin: 40px 0; /* Increased top margin */
  position: relative;
}

.blocks-container {
  overflow-x: auto;
  padding: 40px 0 20px;
  margin: -20px -20px;
  cursor: grab;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}

.blocks-container::-webkit-scrollbar {
  height: 8px;
  background: rgba(255, 255, 255, 0.05);
}

.blocks-container::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.blocks-container::-webkit-scrollbar-thumb:hover {
  background: var(--primary-glow);
}

.blocks-container::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

/* For Firefox */
.blocks-container {
  scrollbar-width: thin;
  scrollbar-color: var(--primary) rgba(255, 255, 255, 0.05);
}

.blocks-scroll {
  display: flex;
  gap: 20px;
  padding: 20px;
  min-width: min-content;
  user-select: none;
  padding-top: 40px;
}

.blocks-container:active {
  cursor: grabbing;
}

.blocks-scroll {
  display: flex;
  gap: 20px;
  padding: 20px;
  min-width: min-content;
  user-select: none;
}

.mining-block {
  min-width: 170px;
  height: 170px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 20px;
  position: relative;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 40px; /* Added margin-top */
}

.mining-block:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 20px rgba(0, 255, 157, 0.1);
}

.block-decoration {
  position: relative;
}

.block-decoration:before {
  content: "";
  width: 27.2px;
  height: 170px;
  position: absolute;
  top: -16.32px;
  left: -27.2px;
  background: rgba(255, 255, 255, 0.03);
  transform: skewY(50deg);
  transform-origin: top;
}

.block-decoration:after {
  content: "";
  width: 170px;
  height: 32.64px;
  position: absolute;
  top: -32.64px;
  left: -27.2px;
  background: rgba(255, 255, 255, 0.05);
  transform: skew(40deg);
  transform-origin: top;
}

.block-number {
  position: absolute;
  top: -40px; /* Adjusted position */
  left: 0;
  right: 0;
  text-align: center;
  color: #00ff9d;
  font-size: 10px;
  padding: 1px;
}
.block-miner-count {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin: 10px 0;
  color: var(--primary);
}

.block-winner {
  font-size: 14px;
  text-align: center;
  margin: 10px 0;
  color: var(--text);
}

.block-reward {
  text-align: center;
  font-size: 12px;
  color: var(--text-secondary);
}

.block-miner {
  font-size: 12px;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
  color: var(--text-secondary);
}

/* Enhanced Session Info */
.session-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 20px 0;
}

.session-stat {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.session-stat label {
  color: var(--text-secondary);
  font-size: 14px;
  margin-bottom: 8px;
  display: block;
}

.session-stat value {
  color: var(--text);
  font-size: 18px;
  font-weight: 500;
}

.participants-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.participant-address {
  background: rgba(255, 255, 255, 0.1);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}

.more-participants {
  color: var(--text-secondary);
  font-size: 12px;
}

.session-status {
  margin-top: 20px;
}

.status {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 9999px;
  font-size: 14px;
  width: fit-content;
}

.status .indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.status.open {
  background: var(--success-glow);
  color: var(--success);
}

.status.open .indicator {
  background: var(--success);
  box-shadow: 0 0 10px var(--success-glow);
}

.status.locked {
  background: var(--danger-glow);
  color: var(--danger);
}

.status.locked .indicator {
  background: var(--danger);
  box-shadow: 0 0 10px var(--danger-glow);
}

.block-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.block-number {
  font-size: 1.2em;
  font-weight: bold;
  color: var(--primary);
}

.block-miners {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--text-secondary);
}

.blocks-container.grabbing {
  cursor: grabbing !important;
}

.blocks-container.grabbing .mining-block {
  pointer-events: none;
}

.loading-more {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 170px;
  height: 170px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  color: var(--primary);
  font-size: 14px;
}

/* Stats Section */
.konkhyperpool-stats {
  background: rgba(17, 17, 17, 0.7);
  border-radius: 16px;
  padding: 24px;
  margin: 24px auto;
  max-width: 1200px;
  backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.stat-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 24px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 20px rgba(0, 255, 157, 0.1);
  border-color: var(--primary);
}

.stat-icon {
  font-size: 24px;
  margin-bottom: 12px;
}

.stat-value {
  font-size: 32px;
  font-weight: bold;
  color: var(--primary);
  margin: 8px 0;
  text-shadow: 0 0 10px rgba(0, 255, 157, 0.3);
}

/* Personal Info Section */
.personal-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.info-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.info-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 20px rgba(0, 255, 157, 0.1);
  border-color: var(--primary);
}

.info-label {
  color: var(--text-secondary);
  font-size: 14px;
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.info-value {
  font-size: 24px;
  font-weight: bold;
  color: var(--primary);
  text-shadow: 0 0 10px rgba(0, 255, 157, 0.3);
}

/* Updated Footer */
.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.blast-svg {
  height: 40px;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.blast-svg:hover {
  opacity: 1;
}

/* Responsive Adjustments */
/* @media (max-width: 768px) {
  .stats-grid,
  .personal-info-grid {
      grid-template-columns: 1fr;
  }
  
  .stat-value {
      font-size: 28px;
  }
  
  .info-value {
      font-size: 20px;
  }
  
  .footer-content {
      flex-direction: column;
  }
} */

/* Animation for stats loading */
@keyframes numberIncrement {
  from {
      opacity: 0;
      transform: translateY(10px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.stat-value {
  animation: numberIncrement 0.5s ease-out;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.pool-status {
  padding: 8px 16px;
  border-radius: 8px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
}

.pool-status.open {
  background: rgba(0, 255, 157, 0.1);
  color: rgb(0, 255, 157);
  border: 1px solid rgb(0, 255, 157);
}

.pool-status.locked, 
.pool-status.paused {
    background: rgba(255, 68, 68, 0.1);
    color: rgb(255, 68, 68);
    border: 1px solid rgb(255, 68, 68);
}

.votes-display {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 0.9em;
}

.position-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.position-item {
  background: rgba(17, 17, 17, 0.7);
  border-radius: 12px;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.position-label {
  color: var(--text-secondary);
  font-size: 14px;
  margin-bottom: 8px;
}

.position-value {
  font-size: 24px;
  color: rgb(0, 255, 157);
  font-weight: bold;
}

.upcoming-sessions {
  grid-column: 1 / -1;
}

.sessions-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.session-item {
  background: rgba(255, 255, 255, 0.05);
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 14px;
}

.position-item.full-width {
    grid-column: 1 / -1;
}

.deposits-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;
}

.deposit-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    padding: 12px;
    border-radius: 8px;
}

.session-id {
    font-weight: 500;
    color: var(--primary);
}

.style {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.9em;
}

.style.smart {
    background: rgba(0, 255, 157, 0.1);
    color: rgb(0, 255, 157);
}

.style.aggressive {
    background: rgba(255, 68, 68, 0.1);
    color: rgb(255, 68, 68);
}

.position-value.sessions {
    font-size: 1.2em;
    letter-spacing: 1px;
}

.session-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.stat-item {
  background: rgba(25, 25, 25, 0.9);
  border-radius: 12px;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  min-height: 120px;
}

.stat-item.participation {
  min-width: 200px;
}

.stat-value.votes {
  font-size: 20px;
  line-height: 1.5;
}

.pool-status {
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.status-indicators {
  display: flex;
  gap: 12px;
  align-items: center;
}

.mining-status {
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  background: rgba(0, 255, 157, 0.1);
  color: rgb(0, 255, 157);
  border: 1px solid rgb(0, 255, 157);
  box-shadow: 0 0 20px rgba(0, 255, 157, 0.15);
  animation: glow 1.5s ease-in-out infinite alternate;
}

.pulse-dot {
  width: 8px;
  height: 8px;
  background-color: rgb(0, 255, 157);
  border-radius: 50%;
  display: inline-block;
  animation: pulse 1.5s infinite;
}

.stat-value.winner {
    color: rgb(0, 255, 157);
    text-shadow: 0 0 10px rgba(0, 255, 157, 0.3);
    animation: glow 1.5s ease-in-out infinite alternate;
}

.mining-live-status {
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  background: rgba(0, 255, 157, 0.1);
  color: rgb(0, 255, 157);
  border: 1px solid rgb(0, 255, 157);
  box-shadow: 0 0 20px rgba(0, 255, 157, 0.15);
  animation: glow 1.5s ease-in-out infinite alternate;
}

.participation-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
}

.participation-info .amount {
  color: var(--primary);
  font-weight: bold;
}

.participation-info .style {
  font-size: 0.8em;
  padding: 2px 8px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
}

.staking-info {
  margin-bottom: 24px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
}

.staking-requirement {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.stake-button {
  background: var(--primary);
  color: black;
  padding: 8px 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

.community-discounts {
  margin-top: 16px;
}

.discount-select {
  width: 100%;
  padding: 8px;
  background: rgba(17, 17, 17, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: white;
  margin-top: 8px;
}

.discount-select option {
  background: rgba(17, 17, 17, 0.9);
  color: white;
  padding: 8px;
}

.discounts-section {
  margin: 24px 0;
  padding: 20px;
  background: rgba(17, 17, 17, 0.7);
  border-radius: 12px;
}

.staking-info h3 {
  margin-bottom: 16px;
  color: var(--text);
}

.discounts-info {
  margin: 24px 0;
}

.discounts-info h3 {
  margin-bottom: 12px;
  color: #00ff9d;
  font-size: 1.1rem;
}

.discount-card {
  background: rgba(17, 17, 17, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 16px;
}

.discounts-section h3 {
  color: #00ff9d;
  margin-bottom: 16px;
}

.additional-discounts {
  margin-top: 24px;
}

.discount-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.discount-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  background: rgba(80, 80, 80, 0.2);
  border-radius: 8px;
}

.discount-item:last-child {
  border-bottom: none;
}

.discount-note {
  margin-top: 16px;
  font-size: 0.9em;
  color: rgba(255, 255, 255, 0.6);
  font-style: italic;
}


.discount-title {
  font-size: 14px;
  color: var(--text-secondary);
  margin-bottom: 8px;
}

.discount-value {
  color: #00ff9d;
}

.discount-status {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active-indicator {
  color: #00ff9d;
  font-size: 16px;
}

.discount-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.discount-status .active {
  color: var(--primary);
  font-weight: bold;
}

.buy-konk {
  background: var(--primary);
  color: black;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
}

.community-discounts {
  margin-bottom: 20px;
}

.discount-select {
  width: 100%;
  padding: 12px;
  background: rgba(17, 17, 17, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: white;
  cursor: pointer;
}

.stat-item .stat-value {
  word-break: break-word;
  overflow-wrap: break-word;
  font-size: 1.2rem; /* Adjust this value if needed */
}


/* Header Social Icons */
.header-right {
  display: flex;
  align-items: center;
  gap: 24px;
}

.social-icons {
  display: flex;
  gap: 16px;
  align-items: center;
}

.social-icon {
  color: white;
  opacity: 0.7;
  transition: opacity 0.2s;
  font-size: 20px;
}

.social-icon:hover {
  opacity: 1;
  color: #00ff9d;
}

.hypersound-icon {
  height: 24px;
  width: auto;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.hypersound-icon:hover {
  opacity: 1;
}

.stake-konk-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: var(--primary);
  color: black;
  border: none;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 12px;
  box-shadow: 0 0 20px var(--primary-glow);
}

.stake-konk-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 30px var(--primary-glow);
}

.stake-konk-button svg {
  color: black;
}

.discounts-section {
  background: rgba(17, 17, 17, 0.7);
  border-radius: 12px;
  margin-bottom: 24px;
}

.discounts-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
  border-radius: 12px;
}

.discounts-header:hover {
  background: rgba(255, 255, 255, 0.05);
}

.discounts-header h3 {
  margin: 0;
  color: var(--primary);
  font-size: 18px;
}

.discounts-toggle {
  background: none;
  border: none;
  color: var(--primary);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.discounts-content {
  margin-top: 2%;
  padding: 0 16px 16px;
  animation: fadeIn 0.3s ease;
}

.community-discounts-section {
  margin-top: 16px;
}

.additional-discounts {
  color: var(--text-secondary);
  margin-bottom: 12px;
  font-size: 14px;
}

.community-discount-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.discount-item {
  display: flex;
  align-items: center;
  gap: 12px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 12px;
}

.discount-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
}

.discount-info {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.discount-details {
  display: flex;
  flex-direction: column;
}

.discount-title {
  color: var(--text-secondary);
  font-size: 14px;
}

.discount-value {
  color: var(--primary);
  font-weight: bold;
}

.stake-konk-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: var(--primary);
  color: black;
  border: none;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 0 20px var(--primary-glow);
}

.stake-konk-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 30px var(--primary-glow);
}

.discount-note {
  margin-top: 16px;
  font-size: 12px;
  color: var(--text-secondary);
  text-align: center;
  font-style: italic;
}

@keyframes fadeIn {
  from {
      opacity: 0;
      max-height: 0;
  }
  to {
      opacity: 1;
      max-height: 1000px;
  }
}

.konk-requirement-modal .modal-body {
  text-align: center;
}

.konk-requirement-modal .minimum-requirement {
  font-size: 12px;
  color: var(--text-secondary);
  margin-top: 8px;
  font-style: italic;
}

.konk-requirement-modal .modal-footer {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 20px;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  header {
      flex-direction: column;
      gap: 16px;
      padding: 16px;
  }

  .header-right {
      width: 100%;
      flex-direction: column;
      align-items: stretch;
      gap: 12px;
  }

  .social-icons {
      justify-content: center;
      margin-bottom: 12px;
  }

  .header-left h1 {
      font-size: 18px;
  }
}

/* Ultra-small screen specific adjustments */
@media (max-width: 480px) {
  .konk-hyper-mining-v2 {
      padding: 10px;
  }

  .stake-konk-button {
    width: 80%;
    max-width: 80%;
    justify-content: center;
    padding: 16px 8px;
    font-size: 12px;
}

.stake-konk-button svg {
    width: px;
    height: 12px;
}

  header {
      padding: 12px;
  }

  h1 {
      font-size: 16px;
  }

  .card {
      padding: 16px;
  }

  .card h2 {
      font-size: 20px;
  }

  .session-stats {
      grid-template-columns: 1fr;
  }

  .stat-item {
      padding: 12px;
  }

  .stat-label {
      font-size: 12px;
  }

  .stat-value {
      font-size: 18px;
  }

  .mining-styles {
      grid-template-columns: 1fr;
  }

  .style-button {
      padding: 12px;
      font-size: 14px;
  }

  .multiplier-slider {
      margin: 12px 0;
  }

  .deposit-button {
      padding: 12px;
      font-size: 14px;
  }

  .discounts-section {
      margin-bottom: 16px;
  }

  .discount-item {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
  }

  .discount-info {
    flex-wrap: wrap;
    gap: 8px;
}

  .stake-konk-button {
      width: 100%;
      justify-content: center;
  }

  .modal-content {
      width: 95%;
      margin: 10px;
      padding: 16px;
  }

  .modal-title {
      font-size: 18px;
  }

  .modal-message {
      font-size: 12px;
      padding: 12px;
  }

  .modal-button {
      padding: 10px 16px;
      font-size: 12px;
  }
}

.header-info {
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
}

.header-info h2 {
  margin: 0;
}

.last-session-box {
  position: relative;
  background: linear-gradient(
      145deg, 
      rgba(0, 255, 157, 0.05) 0%,
      rgba(0, 102, 255, 0.05) 100%
  );
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
}

.last-session-box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
      45deg,
      rgba(0, 255, 157, 0.1) 0%,
      transparent 40%,
      transparent 60%,
      rgba(0, 102, 255, 0.1) 100%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.last-session-box:hover::before {
  opacity: 1;
}

.last-session-label {
  font-size: 0.65rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: var(--text-secondary);
  margin-bottom: 4px;
  text-transform: uppercase;
}

.last-session-value {
  display: flex;
  align-items: baseline;
  gap: 4px;
}

.value-number {
  font-size: 1rem;
  font-weight: 700;
  color: var(--primary);
  font-family: 'JetBrains Mono', monospace;
  letter-spacing: -0.02em;
}

.value-currency {
  font-size: 0.7rem;
  color: var(--text-secondary);
  font-weight: 500;
}

.last-session-box:hover {
  transform: translateY(-2px);
  border-color: var(--primary);
  box-shadow: 
      0 4px 20px rgba(0, 255, 157, 0.15),
      0 0 30px rgba(0, 102, 255, 0.1);
}

.last-session-box:hover .last-session-label {
  color: rgba(255, 255, 255, 0.8);
}

.last-session-box:hover .value-currency {
  color: var(--primary);
}

@keyframes pulse {
  0% {
      box-shadow: 0 0 0 0 rgba(0, 255, 157, 0.4);
  }
  70% {
      box-shadow: 0 0 0 10px rgba(0, 255, 157, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(0, 255, 157, 0);
  }
}

/* Add a subtle animation when new values come in */
@keyframes valueChange {
  0% {
      transform: translateY(5px);
      opacity: 0;
  }
  100% {
      transform: translateY(0);
      opacity: 1;
  }
}

.value-number {
  animation: valueChange 0.3s ease-out;
}

/* Add glass morphism effect */
.last-session-box {
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}

/* Add responsive styles */

/* Tablet and smaller laptops */
@media (max-width: 768px) {
  .header-info {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
  }

  .last-session-box {
      width: 100%;
  }

  .last-session-label {
      font-size: 0.6rem;
  }

  .value-number {
      font-size: 0.9rem;
  }
}

/* Mobile devices */
@media (max-width: 480px) {
  .section-header {
      flex-direction: column;
      gap: 12px;
  }

  .header-info {
      width: 100%;
  }

  .header-info h2 {
      font-size: 1.2rem;
  }

  .last-session-box {
      padding: 6px 8px;
      margin-left: -3%;

      align-items: center;
  }

  .last-session-label {
      font-size: 0.55rem;
      margin-bottom: 0;
  }

  .last-session-value {
      justify-content: flex-end;
  }

  .value-number {
      font-size: 0.8rem;
  }

  .value-currency {
      font-size: 0.6rem;
  }

  .status-indicators {
      width: 100%;
      flex-direction: column;
      gap: 8px;
  }

  .pool-status, .mining-live-status {
      width: 100%;
      justify-content: center;
      font-size: 0.75rem;
      padding: 6px 10px;
  }
}

/* Very small devices */
@media (max-width: 360px) {
  .header-info h2 {
      font-size: 1.1rem;
  }

  .last-session-box {
      padding: 4px 8px;
  }

  .last-session-label {
      font-size: 0.5rem;
  }

  .value-number {
      font-size: 0.75rem;
  }

  .value-currency {
      font-size: 0.55rem;
  }
}