body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Add Tailwind Utility Classes for Vaul */
.fixed { position: fixed; }
.inset-0 { top: 0; right: 0; bottom: 0; left: 0; }
.bottom-0 { bottom: 0; }
.left-0 { left: 0; }
.right-0 { right: 0; }
.z-\[9999\] { z-index: 9999; }
.z-\[10000\] { z-index: 10000; }
.rounded-t-\[20px\] { border-top-left-radius: 20px; border-top-right-radius: 20px; }
.flex { display: flex; }
.flex-col { flex-direction: column; }
.bg-black\/40 { background-color: rgba(0, 0, 0, 0.4); }
.bg-\[\#11140C\] { background-color: #11140C; }
.h-\[85vh\] { height: 85vh; }
.border-t { border-top-width: 1px; }
.border-\[\#ccef22\]\/30 { border-color: rgba(204, 239, 34, 0.3); }
.p-4 { padding: 1rem; }
.mx-auto { margin-left: auto; margin-right: auto; }
.max-w-md { max-width: 28rem; }
.mb-8 { margin-bottom: 2rem; }
.w-12 { width: 3rem; }
.h-1\.5 { height: 0.375rem; }
.flex-shrink-0 { flex-shrink: 0; }
.rounded-full { border-radius: 9999px; }
.bg-\[\#ccef22\]\/30 { background-color: rgba(204, 239, 34, 0.3); }
.font-medium { font-weight: 500; }
.mb-4 { margin-bottom: 1rem; }
.text-\[\#ccef22\] { color: #ccef22; }
.text-xl { font-size: 1.25rem; line-height: 1.75rem; }
.font-chakra-petch { font-family: 'Chakra Petch', sans-serif; }
.text-center { text-align: center; }
.flex-1 { flex: 1 1 0%; }
