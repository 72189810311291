.add-to-homescreen-prompt {
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(26, 26, 26, 0.9);
    color: #FCFDC7;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-width: 480px;
  }
  
  .add-to-homescreen-prompt h3 {
    margin: 0 0 15px 0;
    color: #FCFC03;
    font-size: 24px;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #FCFDC7;
    font-size: 24px;
    cursor: pointer;
  }
  
  .addtohs-image {
    display: block;
    width: 100%;
    max-width: 320px;
    height: auto;
    margin: 0 auto;
  }
  
  @media (max-width: 480px) {
    .add-to-homescreen-prompt {
      width: 95%;
    }
    
    .add-to-homescreen-prompt h3 {
      font-size: 20px;
    }
  }