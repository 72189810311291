@import url('https://fonts.googleapis.com/css2?family=VT323&family=Roboto:wght@300;400;700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #0a0a0a;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
}

.sos-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #121212, #1a1a1a);
  overflow: hidden;
}

.sos-maintenance-overlay {
  background-color: rgba(10, 10, 10, 0.9);
  border-radius: 15px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  max-width: 800px;
  width: 95%;
  padding: 40px;
  text-align: center;
}

.sos-logo {
  max-width: 50px;
  margin-bottom: 10px;
}

.sos-maintenance-content h1 {
  color: #ccef22;
  font-family: 'VT323', monospace;
  font-size: 3rem;
  margin-bottom: 20px;
  letter-spacing: 2px;
}

.sos-message p.white-text,
.sos-detailed-info ul li.white-text,
.sos-closing-note.white-text,
.sos-signature.white-text {
  color: #ffffff !important;
}

.sos-message {
  background-color: rgba(204, 239, 34, 0.05);
  border: 1px solid rgba(204, 239, 34, 0.2);
  padding: 25px;
  border-radius: 10px;
}

.sos-message p {
  line-height: 1.6;
  margin: 10px 0;
}

.sos-countdown-section {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
}

.sos-countdown-timer {
  font-family: 'VT323', monospace;
  font-size: 3rem;
  color: #ccef22;
  letter-spacing: 5px;
}

.sos-timer-description {
  color: #ccef22;
  font-size: 0.9rem;
}

.sos-detailed-info {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
}

.sos-detailed-info ul {
  list-style-type: none;
  padding: 0;
}

.sos-detailed-info ul li {
  background-color: rgba(204, 239, 34, 0.1);
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  padding-left: 30px;
}

.sos-detailed-info ul li::before {
  content: '✓';
  position: absolute;
  left: 10px;
  color: #ccef22;
}

.sos-communication-channels {
    text-align: center; /* Center the entire section */
    margin-top: 20px;
  }
  
  .sos-social-links {
    display: flex; /* Enable horizontal flex layout */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Align items vertically */
    gap: 30px; /* Add spacing between icons and text */
    margin-top: 10px;
  }
  
  /* Social link styling */
  .sos-social-links a {
    display: flex; /* Align icon and text together */
    align-items: center; /* Vertically align items */
    text-decoration: none; /* Remove underline */
    color: #FFFF00; /* Yellow color for text and icons */
    font-size: 1.2rem; /* Adjust text size */
    font-weight: bold; /* Make text bold */
  }


/* Icon styling */
.sos-social-links a i {
  margin-right: 8px; /* Add spacing between icon and text */
  font-size: 1.5rem; /* Slightly larger icons */
  color: #FFFF00; /* Yellow color for icons */
}

/* Hover effect */
.sos-social-links a:hover {
  color: #f2ff3d; /* A slightly different yellow for hover */
  transform: scale(1.1); /* Slight zoom on hover */
  transition: all 0.3s ease;
}

.sos-social-links a i:hover {
  color: #FFD700; /* Icon hover effect */
}

.sos-social-links i {
  font-size: 1.5rem;
}

.sos-closing-note {
  font-style: italic;
  color: #888;
}

.sos-signature {
  margin-top: 20px;
  font-weight: bold;
}

/* Responsive Design */
@media (max-width: 768px) {
  .sos-maintenance-overlay {
    padding: 20px;
    margin: 20px;
  }

  .sos-maintenance-content h1 {
    font-size: 2.5rem;
  }

  .sos-countdown-timer {
    font-size: 2.5rem;
  }

  .sos-social-links {
    flex-direction: column;
    align-items: center;
  }
}

/* Optional Animated Background */
@keyframes gradientShift {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.sos-container {
  background: linear-gradient(-45deg, #121212, #1a1a1a, #0a0a0a);
  background-size: 400% 400%;
  animation: gradientShift 15s ease infinite;
}