html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

/* Game Container */
#gameContainer {
    position: relative;
    width: 800px;
    height: 200px;
    border: 6px solid rgb(0, 0, 0);
    overflow: hidden;
    background-color: #000;
    margin: 0 auto;
}

/* Player Character */
#playerCharacter {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 50px;
    bottom: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2;
}

/* Game Obstacles */
.gameObstacle {
    position: absolute;
    width: 30px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none;
    z-index: 1;
}

/* Game Page */
.game-page {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    position: relative;
    box-sizing: border-box;
}

.game-header {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    z-index: 5;
}

.game-wrapper {
    width: 100%;
    max-width: 800px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

/* Score Container */
#scoreContainer {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    z-index: 3;
}

.score, .jumpPoints, .level {
    font-size: 24px;
    color: white;
    font-weight: bold;
    background: rgba(0, 0, 0, 0.7);
    padding: 5px 10px;
    border-radius: 5px;
}

/* Controls */
#controls {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background: rgba(0, 0, 0, 0.7);
    padding: 15px;
    border-radius: 10px;
    color: #FEA304;
    z-index: 10;
    min-width: 100px;
}

#controls h2 {
    margin: 0 0 2px 0;
    font-size: 14px;
    text-transform: uppercase;
}

#controls p {
    color: white;
    margin: 5px 0;
    font-size: 12px;
}

/* Buttons */
.play-button, .restart-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 100px;
    cursor: pointer;
    z-index: 20;
    transition: transform 0.2s;
}

/* Verification Status */
#verificationStatus {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    z-index: 30;
}

.loader {
    width: 50px;
    height: 50px;
    margin: 0 auto 10px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #FEA304;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

/* Debug Mode */
.debug-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 100;
}

.debug-box.player {
    border: 2px solid red;
    background-color: rgba(255, 0, 0, 0.2);
}

.debug-box.obstacle {
    border: 2px solid blue;
    background-color: rgba(0, 0, 255, 0.2);
}

/* Device Rotation */
.rotate-prompt {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    color: white;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    z-index: 9999;
}

/* Mobile Responsiveness */
@media (max-width: 850px) {
    .game-wrapper {
        padding: 0 10px;
    }
    
    #gameContainer {
        width: 100%;
        height: 180px;
    }
    
    .play-button {
        width: 200px;
        height: 67px;
    }
}

@media (max-width: 850px) and (orientation: portrait) {
    .rotate-prompt {
        display: flex;
    }
    
    .game-wrapper {
        display: none;
    }
}

/* Animations */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Remove blue highlight on mobile tap */
* {
    -webkit-tap-highlight-color: transparent;
    user-select: none;
}