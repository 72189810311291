.drop-container {
    min-height: 100vh;
    background: linear-gradient(135deg, #1a1a1a 0%, #0a0a0a 100%);
    padding: 1.5rem;
}

.header-section {
    background: rgba(31, 31, 31, 0.5);
    backdrop-filter: blur(12px);
    border-bottom: 1px solid rgba(147, 51, 234, 0.2);
    padding: 1.5rem;
    margin: -1.5rem -1.5rem 2rem -1.5rem;
}

.header-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.header-left {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.logo-title {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.header-logo {
    height: 40px;
    width: auto;
}

.home-icon, .docs-icon {
    color: white;
    padding: 0.5rem;
    border-radius: 0.5rem;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-icon:hover, .docs-icon:hover {
    background: rgba(147, 51, 234, 0.1);
    color: rgb(147, 51, 234);
    transform: translateY(-2px);
}

.header-right {
    display: flex;
    align-items: center;
    gap: 1rem;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .header-content {
        padding: 0.5rem;
    }

    .header-logo {
        height: 32px;
    }

    .header-text h1 {
        font-size: 1.5rem;
    }

    .header-text p {
        font-size: 0.9rem;
    }
}

.header-text h1 {
    font-size: 2.5rem;
    font-weight: 800;
    background: linear-gradient(to right, #fff, #a855f7);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin: 0;
}

.header-text p {
    color: #a3a3a3;
    font-size: 1.1rem;
    margin: 0.5rem 0 0 0;
}

.wallet-section {
    padding: 0.5rem;
}

.stats-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    margin-bottom: 2rem;
}

.stat-box {
    background: rgba(31, 31, 31, 0.5);
    border-radius: 1rem;
    padding: 1rem;
    border: 1px solid rgba(147, 51, 234, 0.2);
    transition: all 0.3s ease;
}

.stat-box:hover {
    border-color: rgba(147, 51, 234, 0.5);
    transform: translateY(-2px);
}

.stat-value {
    display: block;
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    margin-bottom: 0.25rem;
}

.stat-label {
    display: block;
    font-size: 0.875rem;
    color: #a3a3a3;
}

.drop-box {
    background: rgba(31, 31, 31, 0.3);
    border-radius: 1rem;
    padding: 1.5rem;
    border: 1px solid rgba(147, 51, 234, 0.2);
}

.mode-tabs {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
}

.tab {
    padding: 0.5rem 1.5rem;
    border-radius: 0.5rem;
    font-weight: 500;
    transition: all 0.3s ease;
    background: rgba(55, 55, 55, 0.5);
    color: #d1d1d1;
    cursor: pointer;
    border: none;
}

.tab:hover {
    background: rgb(147, 51, 234);
    color: white;
}

.tab.active {
    background: rgb(147, 51, 234);
    color: white;
}

.token-input {
    width: 100%;
    background: rgba(17, 17, 17, 0.5);
    box-sizing: border-box;
    border: 1px solid #404040;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    color: white;
    margin-bottom: 1rem;
}

.token-input:focus {
    border-color: rgb(147, 51, 234);
    outline: none;
}

.input-section {
    margin: 1rem -1.5rem -1.5rem -1.5rem;
    padding: 1.5rem;
    background: rgba(0, 0, 0, 0.2);
    border-top: 1px solid rgba(147, 51, 234, 0.2);
    box-sizing: border-box;
}

.file-upload {
    border: 2px dashed #404040;
    border-radius: 0.5rem;
    padding: 2rem;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    color: white;
    margin-bottom: 1rem;
}

.file-upload:hover {
    border-color: rgb(147, 51, 234);
    background: rgba(147, 51, 234, 0.1);
}

.file-upload label {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    color: white;
}

.file-upload input {
    display: none;
}

.upload-icon {
    width: 2rem;
    height: 2rem;
    margin: 0 auto 0.5rem;
    color: white;
}

.file-upload span {
    font-weight: 500;
    color: white;
}

.file-upload:hover .upload-icon {
    color: rgb(147, 51, 234);
    transform: scale(1.1);
    transition: all 0.3s ease;
}

.file-upload:hover span {
    color: rgb(147, 51, 234);
    transition: all 0.3s ease;
}

.wallet-input {
    width: 100%;
    height: 10rem;
    background: rgba(17, 17, 17, 0.5);
    border: 1px solid #404040;
    border-radius: 0.5rem;
    box-sizing: border-box;
    padding: 1.5rem;
    color: white;
    font-family: monospace;
    font-size: 0.875rem;
    resize: none;
    margin-bottom: 1rem;
    text-align: left;
}

.wallet-input:focus {
    border-color: rgb(147, 51, 234);
    outline: none;
}

.wallet-input::placeholder {
    color: #666;
}

.error-message {
    margin-top: 1rem;
    padding: 1rem 1.5rem;
    background: rgba(239, 68, 68, 0.1);
    border: 1px solid rgba(239, 68, 68, 0.5);
    border-radius: 0.5rem;
    color: rgb(252, 165, 165);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    animation: fadeIn 0.3s ease-out;
    gap: 1rem;
}

.error-message span {
    flex: 1;
    word-break: break-word;
    line-height: 1.5;
    font-size: 0.95rem;
}

.error-message button {
    background: none;
    border: none;
    color: rgb(252, 165, 165);
    cursor: pointer;
    padding: 0.25rem;
    border-radius: 0.25rem;
    transition: all 0.3s ease;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.error-message button:hover {
    background: rgba(239, 68, 68, 0.2);
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeOut {
    from { opacity: 1; transform: translateY(0); }
    to { opacity: 0; transform: translateY(-10px); }
}

.airdrop-button {
    width: 100%;
    margin-top: 1.5rem;
    background: linear-gradient(to right, rgb(147, 51, 234), rgb(236, 72, 153));
    color: white;
    font-weight: bold;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    border: none;
    cursor: pointer;
}

.airdrop-button:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(147, 51, 234, 0.3);
}

.airdrop-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.connect-wallet-prompt {
    background: rgba(31, 31, 31, 0.3);
    border-radius: 1rem;
    padding: 3rem;
    text-align: center;
    border: 1px solid rgba(147, 51, 234, 0.2);
    max-width: 500px;
    position: relative;  /* Change from absolute to relative */
    transform: none;    /* Remove the transform */
    left: auto;         /* Remove left positioning */
    top: auto;         /* Remove top positioning */
    margin: auto;      /* This will center it vertically in the available space 
    width: 90%;         /* Responsive width */
}

.connect-wallet-prompt h2 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: white;
}

.connect-wallet-prompt p {
    color: #a3a3a3;
    margin-bottom: 2rem;
}

.connect-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

/* Add this to ensure proper relative positioning */
.drop-container {
    min-height: 100vh;
    background: linear-gradient(135deg, #1a1a1a 0%, #0a0a0a 100%);
    padding: 1.5rem;
    position: relative;  
    display: flex;   
    flex-direction: column; 
}
.eligibility-modal {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    z-index: 50;
}

.modal-content {
    background: #1f1f1f;
    border-radius: 1rem;
    padding: 2rem;
    max-width: 400px;
    width: 90%;
    border: 1px solid rgba(147, 51, 234, 0.2);
    position: relative;
    text-align: center;
}

.modal-content h3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    margin-bottom: 1rem;
}

.modal-content p {
    color: #a3a3a3;
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
    line-height: 1.5;
}

.close-button {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.1);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
    padding: 0;
    font-size: 14px;
}

.close-button:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: rotate(90deg);
}

.buy-button {
    display: block;
    width: 92%;
    background: linear-gradient(to right, rgb(147, 51, 234), rgb(236, 72, 153));
    color: white !important;
    font-weight: bold;
    padding: 1rem;
    border-radius: 0.5rem;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s ease;
    border: none;
    font-size: 1.1rem;
}

.buy-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(147, 51, 234, 0.3);
}

/* Mobile optimization */
@media (max-width: 480px) {
    .modal-content {
        width: calc(100% - 32px);
        padding: 1.5rem;
    }

    .modal-content h3 {
        font-size: 1.25rem;
    }

    .modal-content p {
        font-size: 0.9rem;
        margin-bottom: 1rem;
    }

    .buy-button {
        padding: 0.875rem;
        font-size: 1rem;
    }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .header-content {
        flex-direction: column;
        gap: 1rem;
        text-align: center;
    }

    .header-text h1 {
        font-size: 2rem;
    }

    .mode-tabs {
        flex-wrap: wrap;
    }

    .tab {
        flex: 1 1 auto;
    }

    .connect-wallet-prompt {
        margin: 2rem 1rem;
        padding: 2rem 1rem;
    }
}

/* Animations */
@keyframes float {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
}

@keyframes pulse {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.05); }
}

.stat-box {
    animation: float 6s ease-in-out infinite;
}

.stat-box:hover {
    animation: pulse 2s ease-in-out infinite;
}

/* Add these animations to your existing CSS */
@keyframes floatUpDown {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

@keyframes bounce {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.1); }
}

@keyframes glow {
    0%, 100% { box-shadow: 0 0 5px rgba(147, 51, 234, 0.2); }
    50% { box-shadow: 0 0 20px rgba(147, 51, 234, 0.6); }
}

@keyframes slideIn {
    from { transform: translateX(-100%); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
}

@keyframes fadeInUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

@keyframes bubbleFloat {
    0%, 100% { transform: translate(-50%, 0); }
    50% { transform: translate(-50%, -10px); }
}

@keyframes bubblePop {
    0% { transform: translate(-50%, 20px) scale(0); opacity: 0; }
    50% { transform: translate(-50%, -5px) scale(1.1); opacity: 1; }
    100% { transform: translate(-50%, 0) scale(1); opacity: 1; }
}

/* Fun hover tooltip for Airdrop button */
.airdrop-button {
    width: 100%;
    margin-top: 1.5rem;
    background: linear-gradient(to right, rgb(147, 51, 234), rgb(236, 72, 153));
    color: white;
    font-weight: bold;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    transition: all 0.3s ease;
    position: relative;
    overflow: visible;
    border: none;
    cursor: pointer;
}

.airdrop-button:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(147, 51, 234, 0.3);
}

.airdrop-button::before {
    content: "KONK IT!";
    position: absolute;
    top: -45px;
    left: 50%;
    transform: translate(-50%, 20px) scale(0);
    background: rgb(147, 51, 234);
    padding: 8px 16px;
    border-radius: 25px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    opacity: 0;
    pointer-events: none;
    white-space: nowrap;
    z-index: 1;
    box-shadow: 0 4px 12px rgba(147, 51, 234, 0.3);
}

.airdrop-button:hover::before {
    animation: bubblePop 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards,
              bubbleFloat 2s ease-in-out infinite 0.4s;
}

/* Enhanced animations for existing elements */
.stat-box {
    animation: fadeInUp 0.6s ease-out, glow 3s infinite;
}

.mode-tabs .tab {
    animation: slideIn 0.5s ease-out backwards;
}

.mode-tabs .tab:nth-child(1) { animation-delay: 0.1s; }
.mode-tabs .tab:nth-child(2) { animation-delay: 0.2s; }
.mode-tabs .tab:nth-child(3) { animation-delay: 0.3s; }

.upload-icon {
    animation: bounce 2s infinite;
}



/* Enhanced Mobile Responsiveness */
@media (max-width: 768px) {
    .drop-container {
        padding: 1rem;
    }

    .header-section {
        margin: -1rem -1rem 1rem -1rem;
        padding: 1rem;
    }

    .header-text h1 {
        font-size: 1.8rem;
    }

    .stats-row {
        grid-template-columns: 1fr;
    }

    .mode-tabs {
        flex-direction: column;
    }

    .file-upload {
        padding: 1rem;
    }

    .wallet-input {
        height: 120px;
    }

    .airdrop-button::before {
        display: none; /* Hide tooltip on mobile */
    }
}

/* Tablet Responsiveness */
@media (min-width: 769px) and (max-width: 1024px) {
    .stats-row {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* Add to your KONKDrop.css */
.stats-loading {
    width: 100%;
    text-align: center;
    padding: 2rem;
    color: #d1d1d1;
    background: rgba(31, 31, 31, 0.5);
    border-radius: 1rem;
    border: 1px solid rgba(147, 51, 234, 0.2);
}

.loading-spinner {
    width: 40px;
    height: 40px;
    border: 3px solid rgba(147, 51, 234, 0.2);
    border-radius: 50%;
    border-top-color: rgb(147, 51, 234);
    margin: 0 auto 1rem;
    animation: spin 1s linear infinite;
}

.stats-error {
    width: 100%;
    text-align: center;
    padding: 2rem;
    background: rgba(239, 68, 68, 0.1);
    border-radius: 1rem;
    border: 1px solid rgba(239, 68, 68, 0.2);
    color: rgb(252, 165, 165);
}

.retry-button {
    background: rgba(239, 68, 68, 0.2);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    margin-top: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.retry-button:hover {
    background: rgba(239, 68, 68, 0.3);
}

@keyframes spin {
    to { transform: rotate(360deg); }
}

/* Add hover tooltips for stats */
.stat-box {
    position: relative;
}

.stat-box:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.9);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    white-space: nowrap;
    pointer-events: none;
    opacity: 0;
    animation: fadeIn 0.3s ease forwards;
}

@keyframes fadeIn {
    to { opacity: 1; }
}

.input-summary {
    margin-top: 1rem;
    padding: 1rem;
    background: rgba(147, 51, 234, 0.1);
    border: 1px solid rgba(147, 51, 234, 0.2);
    border-radius: 0.5rem;
    color: white;
}

.input-summary p {
    margin: 0.25rem 0;
    font-size: 0.9rem;
}

.stats-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    margin-bottom: 2rem;
}

.rich-konks {
    background: linear-gradient(45deg, #FF8C00, #FFA500);
    color: white;
    font-weight: bold;
}

.rich-konks-input {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    background: rgba(255, 140, 0, 0.1);
    border-radius: 0.5rem;
    border: 1px solid rgba(255, 140, 0, 0.564);
}

.rich-konks-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    color: #f8c579;
}

.rich-konks-count {
    color: #FF8C00;
    font-weight: bold;
}

.token-inputs {
    display: flex;
    gap: 1rem;
}

.load-addresses-btn {
    background: #FF8C00;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.load-addresses-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(255, 140, 0, 0.3);
}

/* Blast Communities Styles */
.community-input {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1.5rem;
    background: rgba(31, 31, 31, 0.3);
    border-radius: 0.5rem;
    border: 1px solid rgba(147, 51, 234, 0.2);
    overflow: hidden; /* This will keep everything inside */
}

/* Dropdown Styles */
.community-select {
    width: 100%;
    padding: 0.75rem 1rem;
    padding-right: 2.5rem; /* Make room for the arrow */
    background: rgba(17, 17, 17, 0.5);
    border: 1px solid #404040;
    border-radius: 0.5rem;
    color: white;
    font-size: 1rem;
    appearance: none;
    cursor: pointer;
}

/* Custom dropdown arrow */
.community-select-wrapper {
    position: relative;
    width: 100%;
    margin-bottom: 1.5rem;
}

.community-select-wrapper::after {
    content: '▼';
    position: absolute;
    top: 30%;
    right: 1rem;
    transform: translateY(-50%);
    color: #666;
    pointer-events: none;
    font-size: 0.8rem;
}


.community-select:focus {
    border-color: rgb(147, 51, 234);
    outline: none;
    box-shadow: 0 0 0 2px rgba(147, 51, 234, 0.2);
}

.community-select option {
    background: #1a1a1a;
    color: white;
    padding: 0.5rem;
}

/* Minimum Holdings Input */
.min-holding-input {
    width: 100%;
    background: rgba(17, 17, 17, 0.5);
    border: 1px solid #404040;
    border-radius: 0.5rem;
    padding: 0.75rem 1rem;
    color: white;
    margin-bottom: 1rem;
    font-size: 0.95rem;
    box-sizing: border-box;
}

.min-holding-input:focus {
    border-color: rgb(147, 51, 234);
    outline: none;
    box-shadow: 0 0 0 2px rgba(147, 51, 234, 0.2);
}

/* Distribution Mode Styles */
.distribution-mode {
    margin: 1rem 0;
    padding: 1rem;
    background: rgba(17, 17, 17, 0.3);
    border-radius: 0.5rem;
    border: 1px solid #404040;
}

/* Custom Checkbox Design */
.distribution-option {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 0.5rem;
    transition: all 0.3s ease;
}

.distribution-option:hover {
    background: rgba(147, 51, 234, 0.1);
}

/* Hide default radio button */
.distribution-option input[type="radio"] {
    display: none;
}

/* Custom radio button design */
.radio-custom {
    width: 20px;
    height: 20px;
    border: 2px solid #404040;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    transition: all 0.3s ease;
}

/* Radio button selected state */
.distribution-option input[type="radio"]:checked + .radio-custom {
    border-color: rgb(147, 51, 234);
}

.distribution-option input[type="radio"]:checked + .radio-custom::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background: rgb(147, 51, 234);
    border-radius: 50%;
    animation: radioPopIn 0.3s ease-out forwards;
}

/* Label styling */
.distribution-option span {
    color: #d1d1d1;
    font-size: 0.95rem;
    transition: all 0.3s ease;
}

.distribution-option:hover span {
    color: white;
}

.distribution-option input[type="radio"]:checked ~ span {
    color: white;
    font-weight: 500;
}

/* Animation for radio selection */
@keyframes radioPopIn {
    0% {
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
    }
    50% {
        transform: translate(-50%, -50%) scale(1.2);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}

/* Blast Communities Tab Style */
.tab.communities {
    background: linear-gradient(45deg, #1a1a1a, #2a2a2a);
    border: 1px solid #404040;
}

.tab.communities.active {
    background: linear-gradient(45deg, rgb(147, 51, 234), rgb(236, 72, 153));
    border: none;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .distribution-mode {
        flex-direction: column;
        gap: 1rem;
    }

    .distribution-option {
        width: 100%;
        padding: 0.75rem;
        box-sizing: border-box;
    }

    .community-select,
    .min-holding-input {
        font-size: 0.9rem;
    }
}

/* Loading State */
.community-select:disabled,
.min-holding-input:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Error State */
.input-error {
    border-color: #ef4444 !important;
}

.error-text {
    color: #ef4444;
    font-size: 0.875rem;
    margin-top: 0.25rem;
}

/* Success Indicator */
.holders-info {
    margin-top: 1rem;
    padding: 1rem;
    background: rgba(147, 51, 234, 0.1);
    border-radius: 0.5rem;
    color: #d1d1d1;
    text-align: center;
    animation: fadeIn 0.3s ease-out;
}

.holders-count {
    font-weight: bold;
    color: rgb(147, 51, 234);
}

/* Token Distribution Section */
.token-distribution {
    margin: 1rem -1.5rem -1.5rem -1.5rem;
    padding: 1.5rem;
    background: rgba(0, 0, 0, 0.2);
    border-top: 1px solid rgba(147, 51, 234, 0.2);
}

.token-distribution-header {
    font-size: 1rem;
    color: white;
    margin-bottom: 1rem;
    font-weight: 500;
}

/* Add these to your existing CSS */

.community-info {
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(147, 51, 234, 0.2);
}

.community-info h3 {
    font-size: 1.25rem;
    color: white;
    margin: 0 0 0.5rem 0;
    background: linear-gradient(to right, #fff, #a855f7);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.community-info p {
    color: #a3a3a3;
    margin: 0;
    font-size: 0.95rem;
}

.community-select {
    background: rgba(17, 17, 17, 0.5);
    border: 1px solid #404040;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    color: white;
    width: 100%;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.community-select:hover {
    border-color: rgba(147, 51, 234, 0.5);
}

.community-select option {
    background: #1a1a1a;
    padding: 8px;
}

/* Make the token distribution section more compact */
.token-distribution {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-top: 1rem;
}

.token-distribution .token-input {
    margin-bottom: 0;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .token-distribution {
        flex-direction: column;
    }
    
    .token-distribution .token-input {
        margin-bottom: 1rem;
    }
}

/* Add to your CSS */
.community-option {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.community-type-badge {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
    font-weight: 500;
}

.community-type-badge.token {
    background: rgba(147, 51, 234, 0.2);
    color: rgb(147, 51, 234);
}

.community-type-badge.nft {
    background: rgba(236, 72, 153, 0.2);
    color: rgb(236, 72, 153);
}

.refresh-button {
    background: none;
    border: none;
    color: rgb(147, 51, 234);
    cursor: pointer;
    padding: 0.25rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
}

.refresh-button:hover {
    background: rgba(147, 51, 234, 0.1);
}

.refresh-button.refreshing .refresh-icon {
    animation: spin 1s linear infinite;
}

.refresh-icon {
    width: 16px;
    height: 16px;
    transition: transform 0.3s ease;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.drop-box {
    width: 100%;
    box-sizing: border-box;
    padding: 1.5rem;
    background: rgba(31, 31, 31, 0.3);
    border-radius: 1rem;
    border: 1px solid rgba(147, 51, 234, 0.2);
}

.blast-points-section {
    margin: 0;
    padding: 1.5rem;
    background: rgba(17, 17, 17, 0.5);
    border-radius: 0.5rem;
    width: 100%;
    box-sizing: border-box;
}

.token-input-wrapper {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.check-balance-btn {
    background: rgb(147, 51, 234);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    white-space: nowrap;
}

.check-balance-btn:hover:not(:disabled) {
    background: rgb(126, 34, 206);
}

.check-balance-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.blast-balance {
    margin-top: 1rem;
    padding: 1rem;
    background: rgba(147, 51, 234, 0.1);
    border: 1px solid rgba(147, 51, 234, 0.2);
    border-radius: 0.5rem;
    color: white;
}

.balance-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.balance-amounts p {
    display: flex;
    justify-content: space-between;
    color: #d1d1d1;
    margin: 0.5rem 0;
}

.balance-amounts span {
    color: white;
}

.distribution-mode {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.distribution-option {
    flex: 1;
    padding: 1rem;
    background: rgba(31, 31, 31, 0.5);
    border: 1px solid rgba(147, 51, 234, 0.2);
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: white;
}

.distribution-option:hover {
    border-color: rgb(147, 51, 234);
    background: rgba(147, 51, 234, 0.1);
}

.distribution-option input[type="radio"] {
    accent-color: rgb(147, 51, 234);
}

/* Batch Management Styles */
.batch-management {
    margin-top: 1rem;
    padding: 1.5rem;
    background: rgba(31, 31, 31, 0.3);
    border-radius: 0.5rem;
    border: 1px solid rgba(147, 51, 234, 0.2);
}

.batch-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.batch-header h4 {
    color: white;
    margin: 0;
    font-size: 1.1rem;
}

.batch-id {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #a3a3a3;
    font-family: monospace;
    font-size: 0.9rem;
}

.copy-button {
    background: rgba(147, 51, 234, 0.1);
    border: 1px solid rgba(147, 51, 234, 0.2);
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.copy-button:hover {
    background: rgba(147, 51, 234, 0.2);
}

.batch-details {
    background: rgba(17, 17, 17, 0.5);
    padding: 1rem;
    border-radius: 0.5rem;
    margin-top: 1rem;
}

.batch-details p {
    margin: 0.5rem 0;
    color: #d1d1d1;
    display: flex;
    justify-content: space-between;
}

.status-pending { color: #fbbf24; }
.status-finalizing { color: #60a5fa; }
.status-finalized { color: #34d399; }
.status-cancelled { color: #ef4444; }

.batch-actions {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid rgba(147, 51, 234, 0.2);
}

.batch-actions button {
    flex: 1;
    padding: 0.75rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transition: all 0.3s ease;
    cursor: pointer;
}

.refresh-button {
    background: rgba(147, 51, 234, 0.1);
    border: 1px solid rgba(147, 51, 234, 0.2);
    color: white;
}

.refresh-button:hover {
    background: rgba(147, 51, 234, 0.2);
}

.cancel-button {
    background: rgba(239, 68, 68, 0.1);
    border: 1px solid rgba(239, 68, 68, 0.2);
    color: #ef4444;
}

.cancel-button:hover {
    background: rgba(239, 68, 68, 0.2);
}

/* Transaction History Styles */
.transaction-history {
    margin-top: 1rem;
    padding: 1.5rem;
    background: rgba(31, 31, 31, 0.3);
    border-radius: 0.5rem;
    border: 1px solid rgba(147, 51, 234, 0.2);
}

.history-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.history-header h4 {
    color: white;
    margin: 0;
    font-size: 1.1rem;
}

.history-header button {
    background: rgba(147, 51, 234, 0.1);
    border: 1px solid rgba(147, 51, 234, 0.2);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.history-header button:hover {
    background: rgba(147, 51, 234, 0.2);
}

.history-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.history-item {
    background: rgba(17, 17, 17, 0.5);
    padding: 1rem;
    border-radius: 0.5rem;
}

.history-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.history-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    color: #d1d1d1;
    font-size: 0.9rem;
}

.history-details p {
    margin: 0;
}

.operator-info {
    background: rgba(147, 51, 234, 0.1);
    border: 1px solid rgba(147, 51, 234, 0.2);
    border-radius: 0.5rem;
    padding: 1rem;
    margin: 1rem 0;
}

.operator-info p {
    color: white;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.info-icon {
    color: rgb(147, 51, 234);
}

.batch-lookup-section {
    margin-top: 1rem;
    padding: 1.5rem;
    background: rgba(31, 31, 31, 0.3);
    border-radius: 0.5rem;
    border: 1px solid rgba(147, 51, 234, 0.2);
}

.batch-lookup-header {
    margin-bottom: 1rem;
}

.batch-lookup-header h4 {
    color: white;
    margin: 0;
    font-size: 1.1rem;
}

.batch-lookup-form {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    box-sizing: border-box;
}

.batch-input {
    flex: 1;
    min-width: 0; /* This prevents flex items from overflowing */
    background: rgba(17, 17, 17, 0.5);
    border: 1px solid #404040;
    border-radius: 0.5rem;
    padding: 0.75rem 1rem;
    color: white;
    font-family: monospace;
}

.batch-input:focus {
    border-color: rgb(147, 51, 234);
    outline: none;
}

.lookup-button {
    background: rgb(147, 51, 234);
    color: white;
    border: none;
    padding: 0.5rem 1.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    white-space: nowrap;
    box-sizing: border-box;
}

.lookup-button:hover:not(:disabled) {
    background: rgb(126, 34, 206);
    transform: translateY(-1px);
}

.lookup-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.batch-lookup-note {
    color: #a3a3a3;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    text-align: center;
    padding: 0.5rem;
    background: rgba(147, 51, 234, 0.1);
    border-radius: 0.25rem;
}

.footer {
    margin-top: auto;  /* This pushes it to the bottom */
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.blast-logo {
    width: 128px;
    height: 128px;
    filter: invert(98%) sepia(18%) saturate(219%) hue-rotate(34deg) brightness(103%) contrast(96%);
    opacity: 0.8;
    transition: all 0.3s ease;
}

.blast-logo:hover {
    opacity: 1;
    transform: scale(1.1);
}

.footer-text {
    color: #f2ddfe;
    font-size: 0.9rem;
    opacity: 0.8;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-top: 00.01%;
}

.operator-deployment-section {
    margin-top: 1rem;
    padding: 1.5rem;
    background: rgba(31, 31, 31, 0.3);
    border-radius: 0.5rem;
    border: 1px solid rgba(147, 51, 234, 0.2);
}

.deployment-form {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.deploy-button {
    background: linear-gradient(to right, rgb(147, 51, 234), rgb(236, 72, 153));
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    white-space: nowrap;
}

.deploy-button:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(147, 51, 234, 0.3);
}

.deploy-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.airdrop-button {
    width: 100%;
    margin-top: 1.5rem;
    background: linear-gradient(to right, rgb(147, 51, 234), rgb(236, 72, 153));
    color: white;
    font-weight: bold;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.airdrop-button:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(147, 51, 234, 0.3);
}

.airdrop-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.airdrop-button.loading {
    background: linear-gradient(to right, rgb(147, 51, 234), rgb(236, 72, 153));
    pointer-events: none;
    opacity: 0.8;
}

.airdrop-button.approved {
    background: linear-gradient(to right, rgb(34, 197, 94), rgb(16, 185, 129));
}

.airdrop-button.approved:hover {
    background: linear-gradient(to right, rgb(21, 128, 61), rgb(13, 148, 103));
}

.spinner {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: white;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* Add a success animation for when approval completes */
@keyframes successPulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
}

/* Transaction Notification Styles */
.transaction-notification {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99999;
    animation: slideIn 0.3s ease-out;
    pointer-events: auto;
    width: auto;
    max-width: calc(100% - 40px); /* 20px padding on each side */
}

.notification-content {
    background: rgba(48, 15, 67, 0.95);
    border: 1px solid rgb(162, 0, 255);
    backdrop-filter: blur(8px);
    padding: 1.5rem;
    border-radius: 1rem;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    min-width: 320px;
    max-width: 100%;
    box-shadow: 0 4px 20px rgba(59, 0, 86, 0.3);
    position: relative;
}

.notification-message {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    word-break: break-word;
}

.success-icon {
    color: rgb(129, 197, 34);
    flex-shrink: 0;
    margin-top: 2px;
}

.transaction-hash {
    display: block;
    color: rgb(236, 216, 255);
    text-decoration: none;
    font-family: monospace;
    font-size: 0.9rem;
    padding: 0.5rem 0.75rem;
    background: rgba(146, 51, 234, 0.636);
    border-radius: 0.5rem;
    transition: all 0.3s ease;
    text-align: center;
    word-break: break-all;
}

.transaction-hash:hover {
    background: rgba(147, 51, 234, 0.8);
    transform: translateY(-1px);
}

.close-notification {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    background: rgba(146, 51, 234, 0.2);
    border: none;
    color: white;
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    opacity: 0.7;
    transition: all 0.3s ease;
}

.close-notification:hover {
    opacity: 1;
    background: rgba(146, 51, 234, 0.4);
    transform: rotate(90deg);
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

/* Mobile adjustments */
@keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .transaction-notification {
        bottom: 10px;
        right: 10px;
        left: 10px;
        max-width: 350px;
    }
    
    .notification-content {
        min-width: 0;
        width: 90%;
        padding: 1rem;
    }

    .notification-message {
        font-size: 0.9rem;
    }

    .transaction-hash {
        font-size: 0.8rem;
        padding: 0.4rem;
    }
}

.leaderboard-section {
    margin-top: 1rem;
    padding: 1.5rem;
    background: rgba(31, 31, 31, 0.3);
    border-radius: 0.5rem;
    border: 1px solid rgba(147, 51, 234, 0.2);
}

.leaderboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.leaderboard-header h4 {
    color: white;
    margin: 0;
    font-size: 1.1rem;
}

.toggle-form-button {
    background: rgb(147, 51, 234);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.toggle-form-button:hover {
    background: rgb(126, 34, 206);
}

.leaderboard-form {
    background: rgba(17, 17, 17, 0.5);
    padding: 1.5rem;
    border-radius: 0.5rem;
    margin-top: 1rem;
}

.form-info {
    display: flex;
    gap: 0.5rem;
    padding: 1rem;
    background: rgba(147, 51, 234, 0.1);
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;
}

.form-info p {
    color: #d1d1d1;
    margin: 0;
    font-size: 0.9rem;
    line-height: 1.5;
}

.form-group {
    margin-bottom: 1rem;
}

.leaderboard-input,
.leaderboard-select {
    width: 100%;
    background: rgba(31, 31, 31, 0.5);
    border: 1px solid #404040;
    border-radius: 0.5rem;
    padding: 0.75rem 1rem;
    color: white;
    margin-top: 0.25rem;
    box-sizing: border-box;
}

.leaderboard-input:focus,
.leaderboard-select:focus {
    border-color: rgb(147, 51, 234);
    outline: none;
}

.leaderboard-select option {
    background: #1a1a1a;
    color: white;
}

.register-button {
    width: 100%;
    background: rgb(147, 51, 234);
    color: white;
    border: none;
    padding: 0.75rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 1rem;
}

.register-button:hover:not(:disabled) {
    background: rgb(126, 34, 206);
}

.register-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}