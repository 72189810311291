.staking-container {
    width: 100%;
    min-height: 100vh;
    padding: 2rem 0; /* Remove horizontal padding */
    color: white;
    position: relative;
    background: url('../assets/stakingbg.png') no-repeat center center fixed;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center children horizontally */
}

.staking-header {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto 3rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 0; /* Remove padding */
}

.home-link {
  color: white;
  opacity: 0.8;
  transition: opacity 0.2s;
  -webkit-tap-highlight-color: transparent;
}

.staking-footer {
    margin-top: auto;
    width: 100%;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    position: absolute;
    bottom: 0;
    left: 0;
}

.footer-logo {
    width: 120px; /* Adjust size as needed */
    height: 120px;
    filter: invert(0%) sepia(42%) saturate(453%) hue-rotate(127deg) brightness(96%) contrast(101%);
    /* This filter creates the cyan/blue color matching your theme */
}

.footer-text {
    color: rgb(47, 209, 199); /* Match the theme color */
    font-size: 0.9rem;
    opacity: 0.8;
}

.home-link:hover {
  opacity: 1;
}

.home-link:hover {
  opacity: 1;
}

.header-content {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content h1 {
  font-size: 2.5rem;
  margin: 0;
}

.header-content h1 span:first-child {
  color: #ffffff;
}

.header-content h1 span:last-child {
  color: #ffffff;
}

.subtitle {
    max-width: 800px;
    margin: 0 auto 3rem;
    text-align: center;
    font-size: 1.2rem;
    opacity: 0.8;
}

.stats-container {
    max-width: 1400px; /* You can adjust this */
    margin: 0 auto;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1.2rem;
    margin-bottom: 3rem;
}
  
  .stat-box1 {
    background: rgba(31, 31, 31, 0.5); 
    border-radius: 1rem;
    padding: 1.5rem;
    border: 1px solid rgba(47, 209, 199, 0.2);
    text-align: center;
    transition: all 0.3s ease;

    box-shadow: 0 0 15px rgba(47, 209, 199, 0.15);
  }
  
  .stat-box1:hover {
    border-color: rgb(0, 255, 238);
    transform: translateY(-2px);
    animation: pulse 2s ease-in-out infinite;
    box-shadow: 0 0 20px rgba(0, 255, 238, 0.3);
  }
   
   .stat-box1 h3 {
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    opacity: 0.8;
    color: #4ECDC4;
   }
   
   .stat-box1 p {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
   }
   
   .stat-box1 .description {
    font-size: 0.8rem;
    opacity: 0.6;
   }

   .action-box {
    max-width: 600px;
    margin: 0 auto;
    background: rgba(31, 31, 31, 0.5);
    padding: 2rem;
    border-radius: 1rem;
    backdrop-filter: blur(10px);
    overflow: hidden;
    width: 100%;
    border: 1px solid rgba(47, 209, 199, 0.2);
    box-shadow: 0 0 20px rgba(47, 209, 199, 0.1);
    transition: all 0.3s ease;
    margin-bottom: 100px;
  }
  
  .action-box:hover {
    box-shadow: 0 0 30px rgba(47, 209, 199, 0.2);
  }
  
  .mode-toggle {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .mode-btn {
    flex: 1;
    padding: 1rem;
    background: rgba(31, 31, 31, 0.5);
    border: 1px solid rgba(47, 209, 199, 0.2);
    border-radius: 0.8rem;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
    position: relative;
    overflow: hidden;
  }
  
  .mode-btn:hover {
    border-color: rgb(0, 255, 238);
    transform: translateY(-2px);
    box-shadow: 0 0 15px rgba(0, 255, 238, 0.2);
  }
  
  .mode-btn.active {
    background: linear-gradient(45deg, rgba(47, 209, 199, 0.2), rgba(0, 255, 238, 0.2));
    border-color: rgb(0, 255, 238);
    box-shadow: 0 0 20px rgba(0, 255, 238, 0.3);
  }
  
  .amount-input {
    width: 100%;
    background: rgba(31, 31, 31, 0.5);
    border: 1px solid rgba(47, 209, 199, 0.2);
    padding: 1rem;
    border-radius: 0.8rem;
    color: white;
    margin-bottom: 1rem;
    font-size: 1rem;
    box-sizing: border-box;
    transition: all 0.3s ease;
  }
  
  .amount-input:focus {
    outline: none;
    border-color: rgb(0, 255, 238);
    box-shadow: 0 0 15px rgba(0, 255, 238, 0.2);
  }
  
  .percentage-buttons button {
    padding: 0.5rem;
    background: rgba(31, 31, 31, 0.5);
    border: 1px solid rgba(47, 209, 199, 0.2);
    border-radius: 0.5rem;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-bottom: 1.5%;
    font-size: 0.9rem;
  }
  
  .percentage-buttons button:hover {
    border-color: rgb(0, 255, 238);
    transform: translateY(-2px);
    box-shadow: 0 0 15px rgba(0, 255, 238, 0.2);
  }
  
  .approve-button {
    width: 100%;
    padding: 1rem;
    background: rgba(31, 31, 31, 0.5);
    border: 1px solid rgba(47, 209, 199, 0.2);
    border-radius: 0.8rem;
    color: white;
    cursor: pointer;
    margin-bottom: 1rem;
    transition: all 0.3s ease;
    font-weight: 500;
  }
  
  .approve-button:hover:not(:disabled) {
    border-color: rgb(0, 255, 238);
    transform: translateY(-2px);
    box-shadow: 0 0 20px rgba(0, 255, 238, 0.3);
  }
  
  .main-action-button {
    width: 100%;
    padding: 1rem;
    background: linear-gradient(45deg, rgba(47, 209, 199, 0.8), rgba(0, 255, 238, 0.8));
    border: none;
    border-radius: 0.8rem;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .main-action-button:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 0 25px rgba(0, 255, 238, 0.4);
  }
  
  .main-action-button::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, transparent, rgba(255, 255, 255, 0.1), transparent);
    transform: translateX(-100%);
    transition: 0.5s;
  }
  
  .main-action-button:hover::after {
    transform: translateX(100%);
  }
  
  .notification1 {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    padding: 1.2rem 1.5rem;
    border-radius: 0.8rem;
    background: rgba(31, 31, 31, 0.9);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
    z-index: 1000;
    max-width: 400px;
    border: 1px solid rgba(47, 209, 199, 0.2);
    box-shadow: 0 0 20px rgba(47, 209, 199, 0.1);
    animation: slideIn 0.3s ease-out;
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.notification1.closing {
    opacity: 0;
    transform: translateX(100%);
}
  
  .notification1.success {
    border: 1px solid rgba(47, 209, 199, 0.5);
    box-shadow: 0 0 20px rgba(47, 209, 199, 0.2);
  }
  
  .notification1.error {
    border: 1px solid rgba(255, 107, 107, 0.5);
    box-shadow: 0 0 20px rgba(255, 107, 107, 0.2);
  }
  
  .notification1-content1 {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .tx-link {
    color: rgb(47, 209, 199);
    text-decoration: none;
    font-size: 0.9rem;
    opacity: 0.8;
    transition: opacity 0.2s;
  }
  
  .tx-link:hover {
    opacity: 1;
  }
  
  .close-button {
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    padding: 0.5rem;
    font-size: 1.2rem;
    line-height: 1;
    transition: all 0.2s ease;
    border-radius: 50%;
  }
  
  .close-button:hover {
    color: white;
    background: rgba(255, 255, 255, 0.1);
  }
  
  @keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}
/* 
@keyframes slideOut {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(100%);
        opacity: 0;
    }
} */
  
  .notification1.hide {
    animation: slideOut 0.3s ease-out forwards;
  }

  .button-loader {
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: white;
    display: inline-block;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideOut {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(100%);
      opacity: 0;
    }
  }

@keyframes float {
    0%, 100% { 
      transform: translateY(0);
      box-shadow: 0 0 15px rgba(47, 209, 199, 0.15);
    }
    50% { 
      transform: translateY(-10px);
      box-shadow: 0 0 20px rgba(47, 209, 199, 0.25);
    }
  }
  
  @keyframes pulse {
    0%, 100% { 
      transform: scale(1);

    }
    50% { 
      transform: scale(1.05);

    }
  }

  @media (max-width: 768px) {
    .staking-container {
        padding: 1rem 0;
        width: 100%;
    }

    .content-wrapper {
        padding: 0 1rem;
        width: 100%;
    }

    .stats-container {
        grid-template-columns: repeat(2, 1fr);
        gap: 0.8rem;
        margin-bottom: 2rem;
    }

    .staking-header {
        flex-direction: column;
        margin-bottom: 2rem;
        text-align: center;
    }

    .header-content {
        gap: 1rem;
    }

    .header-content h1 {
        font-size: 2rem;
    }

    .subtitle {
        font-size: 1rem;
        margin-bottom: 2rem;
        padding: 0 1rem;
    }

    .stat-box1 {
        padding: 1rem;
    }

    .stat-box1 h3 {
        font-size: 0.9rem;
    }

    .stat-box1 p {
        font-size: 1.2rem;
    }

    .stat-box1 .description {
        font-size: 0.7rem;
    }

    .action-box {
        padding: 1rem;
        margin-bottom: 80px;
    }

    .mode-btn {
        padding: 0.8rem;
        font-size: 0.9rem;
    }

    .amount-input {
        padding: 0.8rem;
        font-size: 0.9rem;
    }

    .percentage-buttons button {
        padding: 0.4rem;
        font-size: 0.8rem;
    }

    .main-action-button {
        padding: 0.8rem;
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .staking-container {
        padding: 1rem 0;
        width: 100vw;
        min-width: 100%;
        margin: 0;
        overflow-x: hidden;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
    }

    body {
        overflow-x: hidden;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .content-wrapper {
        padding: 0 1rem;
        width: 100%;
        max-width: none;
        margin: 0;
    }

    .stats-container {
        display: flex;
        flex-direction: column;
        gap: 0.6rem;
        width: calc(100% - 2rem);
        margin: 0 1rem 3rem;
        padding: 0;
    }
 
    .stat-box1 {
        width: 100%;
        padding: 0.2rem;
        margin: 0;
    }

    .staking-header {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        margin-bottom: 1rem;
    }

    .header-content {
        width: 100%;
        text-align: center;
    }

    .header-content h1 {
        font-size: 1.8rem;
    }

    .subtitle {
        font-size: 0.9rem;
        padding: 0 0.5rem;
        margin-bottom: 1.5rem;
    }

    .stat-box1:last-child {
        width: 100%;
    }

    .stat-box1 h3 {
        font-size: 0.85rem;
        margin-bottom: 0.3rem;
    }

    .stat-box1 p {
        font-size: 1.1rem;
        margin-bottom: 0.3rem;
    }

    .stat-box1 .description {
        font-size: 0.7rem;
    }

    .action-box {
        width: calc(100% - 4rem);  /* Full width minus margins */
        margin: 0 1rem 120px;
        padding: 1rem;
        max-width: none;
    
    }

    .mode-toggle {
        gap: 0.5rem;
        margin-bottom: 1rem;
    }

    .mode-btn {
        padding: 0.7rem;
        font-size: 0.85rem;
    }

    .amount-input {
        padding: 0.7rem;
        font-size: 0.85rem;
        margin-bottom: 0.8rem;
    }

    .percentage-buttons {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 0.5rem;
        margin-bottom: 1rem;
    }

    .percentage-buttons button {
        padding: 0.4rem;
        font-size: 0.75rem;
        margin: 0;
    }

    .main-action-button {
        padding: 0.8rem;
        font-size: 0.85rem;
    }

    .notification1 {
        left: 0.5rem;
        right: 0.5rem;
        bottom: 0.5rem;
        padding: 1rem;
        font-size: 0.85rem;
    }

    .tx-link {
        font-size: 0.8rem;
    }

    .close-button {
        padding: 0.3rem;
        font-size: 1rem;
    }

    .staking-footer {
        padding: 0.8rem 0;
    }

    .footer-logo {
        width: 120px;
        height: 120px;
    }

    .footer-text {
        font-size: 0.8rem;
    }
}